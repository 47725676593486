import MetarData from "../interfaces/metar"

interface WeatherData {
    [key: string]: {
        value: any
        units?: string
    }
}

interface MetarParsedData {
    latitude: number
    longitude: number
    properties?: { [key: string]: any }
}
export function createPopUpKeyValuePair(
    data: WeatherData
): { key: string; value: any }[] {
    return Object.entries(data).map(([key, value]) => ({
        key,
        value: value.value,
    }))
}

export function METARAPIParser(apiresponse: MetarData) {
    const parsedArray: MetarParsedData[] = []
    if (apiresponse.features.message) {
        throw new Error(apiresponse.features.message)
    }
    apiresponse?.features?.forEach((feature) => {
        const newObject: MetarParsedData = {
            latitude: feature.geometry.coordinates[1],
            longitude: feature.geometry.coordinates[0],
            properties: feature.properties,
        }
        parsedArray.push(newObject)
    })
    return parsedArray
}
