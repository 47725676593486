import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import useTWSMapContext from '../../hooks/UseTWSContext'
import CameraNetwork from '../CameraNetwork/CameraNetwork'
import VerticalProfile from '../VerticalProfile/VerticalProfile'
import {
  WMS80MWinds,
  WMSSurfaceWinds,
  WMSTemperature,
  WMSVisibility,
  WMSCeiling,
  WMSSurfaceWindsCA,
  WMS80MWindsCA,
  WMS80MWindsUK,
  WMSSurfaceWindsUK,
  WMSTemperatureUK,
  WMSVisibilityUK,
  WMSCeilingUK,
  WMSWindGust,
} from '../WMSLayerComponents/WMSWeatherLayers'
import { toggleMissionCast } from '../MissionCAST/MissionCASTSlice'
import { showAddLocationPopup } from '../Users/UserSlice'
import {
  SatelliteComponent,
  SectionalChartComponent,
} from '../../map/BaseLayers'
import { setActiveBaseLayer } from '../../map/mapSlice'
import {
  hideAlertSETTING,
  setLocation,
  setLocationType,
  showAlertSETTING,
  showAlertSETTINGCreate,
} from '../Alerting/AlertingSlice/AlertingSlice'
import { toggleRouteCast } from '../DecisionProducts/RouteCast/routecastSlice'
import RouteCAST from '../DecisionProducts/RouteCast/RouteCAST'
import { setCurLayer } from '../WetdogWeatherProducts/WetDogWeatherProductsSlice'
import useErrorStatus from '../../hooks/UseErrorStatus'
import { WetDogWeatherLayersEnum } from '../../interfaces/WetDogWeatherLayers'
// Add other imports for additional components as needed

function MenuSelection() {
  const defaultLocation = useSelector(
    (state: RootState) => state.user.defaultLocation
  )
  const selection = useSelector(
    (state: RootState) => state.sideNav.selectedMenuItem
  )
  const selectedItems = useSelector(
    (state: RootState) => state.sideNav.selectedItems
  )
  const selectedItemIds = useSelector(
    (state: RootState) => state.sideNav.selectedItemIds
  )
  const { addToTWS, removeFromTWS, twsElements } = useTWSMapContext()
  const { id } = selection !== null ? selection : { id: null }
  const baseLayerUtils = useSelector(
    (state: RootState) => state.mapState.baseLayerUtil
  )
  const dispatch = useDispatch()
  const [currTile, setCurrTile] = useState<string>('')
  const [currTileName, setCurrTileName] = useState<string>('')
  const currentLocation = useSelector(
    (state: RootState) => state.user.defaultLocation
  )

  const eventCount = useSelector((state) => state.alert.eventCount)
  const errorStatus = useErrorStatus()

  const tileProducts = [
    'observations-myradar',
    'observations-mrms-radar',
    'decision-missioncast',
    // 'camera-network',
    'aviation-winds-80m',
    'aviation-temperature',
    'aviation-visibility',
    'aviation-surface-winds',
    'aviation-ceiling',
    'aviation-wind-gust',
    'aviation-surface-winds-ca',
    'aviation-winds-80m-ca',
    'aviation-winds-80m-uk',
    'aviation-surface-winds-uk',
    'aviation-temperature-uk',
    'aviation-visibility-uk',
    'aviation-ceiling-uk',
  ]

  useEffect(() => {
    if (selectedItemIds.includes('camera-network')) {
      removeFromTWS('camera-network')
      addToTWS(
        <CameraNetwork
          key={'camera-network'}
          position={[currentLocation.latitude, currentLocation.longitude]} //[47.925278, -97.0325]
        />
      )
    }
  }, [currentLocation])

  useEffect(() => {
    const newActiveLayer = baseLayerUtils[1]
    const oldActiveLayer = baseLayerUtils[0]
    switch (newActiveLayer) {
      case 'maps-road':
        if (oldActiveLayer === 'maps-satellite') {
          removeFromTWS('maps-satellite')
          dispatch(setActiveBaseLayer('maps-road'))
        } else if (oldActiveLayer === 'maps-sectional') {
          removeFromTWS('maps-sectional')
          dispatch(setActiveBaseLayer('maps-road'))
        }
        break
      case 'maps-satellite':
        if (oldActiveLayer === 'maps-road') {
          addToTWS(
            <SatelliteComponent
              url={
                'https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryTopo/MapServer/tile/{z}/{y}/{x}'
              }
              attribution=""
              key="maps-satellite"
              opacity={0.6}
            />
          )
          dispatch(setActiveBaseLayer('maps-satellite'))
        } else if (oldActiveLayer === 'maps-sectional') {
          removeFromTWS('maps-sectional')
          addToTWS(
            <SatelliteComponent
              url={
                'https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryTopo/MapServer/tile/{z}/{y}/{x}'
              }
              attribution=""
              key="maps-satellite"
              opacity={0.6}
            />
          )
          dispatch(setActiveBaseLayer('maps-satellite'))
        }
        break
      case 'maps-sectional':
        if (oldActiveLayer === 'maps-road') {
          addToTWS(
            <SectionalChartComponent
              url={'http://wms.chartbundle.com/wms'}
              // url={'https://vfrmap.com/20231130/tiles/vfrc/?service=WMS'}
              key="maps-sectional"
              attribution=""
              opacity={0.8}
            />
          )
          dispatch(setActiveBaseLayer('maps-sectional'))
        } else if (oldActiveLayer === 'maps-satellite') {
          removeFromTWS('maps-satellite')
          addToTWS(
            <SectionalChartComponent
              url={'http://wms.chartbundle.com/wms'}
              // url={'https://vfrmap.com/20231130/tiles/vfrc/?service=WMS'}
              key="maps-sectional"
              attribution=""
              opacity={0.8}
            />
          )
          dispatch(setActiveBaseLayer('maps-sectional'))
        }
        break
      default:
        break
    }
  }, [baseLayerUtils])

  const persistantProducts = [
    // these items need to not change when switching between
    // normal and dark mode.
    'observations-myradar',
    'observations-mrms-radar',
    'aviation-ceiling',
    'aviation-visibility',
    'aviation-temperature',
    'aviation-surface-winds',
    'aviation-winds-80m',
    'aviation-wind-gust',
    'aviation-surface-winds-ca',
    'aviation-winds-80m-ca',
    'aviation-surface-winds-uk',
    'aviation-winds-80m-uk',
    'aviation-temperature-uk',
    'aviation-visibility-uk',
    'aviation-ceiling-uk',
    'forecast-aviation-ceiling',
    'forecast-aviation-visibility',
    'forecast-aviation-temperature',
    'forecast-aviation-surface-winds',
    'forecast-aviation-winds-80m',
    'forecast-aviation-wind-gust',
  ]

  useEffect(() => {
    if (
      (id == currTile || id == null) &&
      !selectedItems.includes(currTileName)
    ) {
      /**
       * HH - adding checks for weather viz layers so
       * they don't get removed when we want to keep them
       */
      if (
        !selectedItemIds.some((element) => persistantProducts.includes(element))
      ) {
        dispatch(
          setCurLayer({
            layer: -1,
            mode: 'forecast',
          })
        )
        removeFromTWS(currTile)
        setCurrTile('')
        setCurrTileName('')
      }
      return
    }

    if (tileProducts.includes(id) && currTile != '') {
      dispatch(
        setCurLayer({
          layer: -1,
          mode: '',
        })
      )
      removeFromTWS(currTile)
    }

    const sel: string = selection !== null ? selection.id : ''
    if (sel.startsWith('maps-')) {
      // processMapSelection(selection.id)
    }

    // Assert that id is a string (since we've already checked that it's not null or undefined)
    const idString = id as string
    switch (idString) {
      case 'camera-network':
        addToTWS(
          <CameraNetwork
            key={'camera-network'}
            position={[defaultLocation.latitude, defaultLocation.longitude]} //[47.925278, -97.0325]
          />
        )
        break
      case 'aviation-winds-80m':
        // addToTWS(<WMS80MWinds key={'aviation-winds-80m'} opacity={0.85} />)
        dispatch(
          setCurLayer({
            mode: 'current',
            layer: WetDogWeatherLayersEnum.CURRENT_WIND80M,
          })
        )
        break
      // Add more cases for other components here
      case 'aviation-temperature':
        // addToTWS(<WMSTemperature key={'aviation-temperature'} opacity={0.45} />)
        dispatch(
          setCurLayer({
            mode: 'current',
            layer: WetDogWeatherLayersEnum.CURRENT_TEMPERATURE,
          })
        )
        break
      case 'aviation-visibility':
        // addToTWS(<WMSVisibility key={'aviation-visibility'} opacity={0.45} />)
        dispatch(
          setCurLayer({
            mode: 'current',
            layer: WetDogWeatherLayersEnum.CURRENT_CLOUDCOVER,
          })
        )
        break
      case 'aviation-surface-winds':
        // addToTWS(
        //   <WMSSurfaceWinds key={'aviation-surface-winds'} opacity={0.85} />
        // )
        dispatch(
          setCurLayer({
            mode: 'current',
            layer: WetDogWeatherLayersEnum.CURRENT_WINDUV,
          })
        )
        break
      case 'aviation-ceiling':
        // addToTWS(<WMSCeiling key={'aviation-ceiling'} opacity={0.45} />)
        dispatch(
          setCurLayer({
            mode: 'current',
            layer: WetDogWeatherLayersEnum.CURRENT_CLOUDCEILING,
          })
        )
        break
      case 'aviation-wind-gust':
        dispatch(
          setCurLayer({
            mode: 'current',
            layer: WetDogWeatherLayersEnum.CURRENT_WINDGUST,
          })
        )
        break
      case 'forecast-aviation-winds-80m':
        // addToTWS(<WMS80MWinds key={'aviation-winds-80m'} opacity={0.85} />)
        dispatch(
          setCurLayer({
            mode: 'forecast',
            layer: WetDogWeatherLayersEnum.WIND80M,
          })
        )
        break
      // Add more cases for other components here
      case 'forecast-aviation-temperature':
        // addToTWS(<WMSTemperature key={'aviation-temperature'} opacity={0.35} />)
        dispatch(
          setCurLayer({
            mode: 'forecast',
            layer: WetDogWeatherLayersEnum.TEMPERATURE,
          })
        )
        break
      case 'forecast-aviation-visibility':
        dispatch(
          setCurLayer({
            mode: 'forecast',
            layer: WetDogWeatherLayersEnum.CLOUDCOVER,
          })
        )
        break
      case 'forecast-aviation-surface-winds':
        dispatch(
          setCurLayer({
            mode: 'forecast',
            layer: WetDogWeatherLayersEnum.WINDUV,
          })
        )
        break
      case 'forecast-aviation-ceiling':
        dispatch(
          setCurLayer({
            mode: 'forecast',
            layer: WetDogWeatherLayersEnum.CLOUDCEILING,
          })
        )
        break
      case 'forecast-aviation-wind-gust':
        dispatch(
          setCurLayer({
            mode: 'forecast',
            layer: WetDogWeatherLayersEnum.WINDGUST,
          })
        )
        break
      case 'aviation-surface-winds-ca':
        addToTWS(
          <WMSSurfaceWindsCA key={'aviation-surface-winds-ca'} opacity={0.85} />
        )
        break
      case 'aviation-winds-80m-ca':
        addToTWS(<WMS80MWindsCA key={'aviation-winds-80m-ca'} opacity={0.85} />)
        break
      case 'aviation-winds-80m-uk':
        addToTWS(<WMS80MWindsUK key={'aviation-winds-80m-uk'} opacity={0.85} />)
        break
      case 'aviation-surface-winds-uk':
        addToTWS(
          <WMSSurfaceWindsUK key={'aviation-surface-winds-uk'} opacity={0.85} />
        )
        break
      case 'aviation-temperature-uk':
        addToTWS(
          <WMSTemperatureUK key={'aviation-temperature-uk'} opacity={0.4} />
        )
        break
      case 'aviation-visibility-uk':
        addToTWS(
          <WMSVisibilityUK key={'aviation-visibility-uk'} opacity={0.4} />
        )
        break
      case 'aviation-ceiling-uk':
        addToTWS(<WMSCeilingUK key={'aviation-ceiling-uk'} opacity={0.4} />)
        break
      case 'decision-missioncast':
        dispatch(toggleMissionCast(true))
        break
      case 'alerts-create':
        if (selectedItemIds.includes('alerts-create')) {
          if (eventCount < 3) {
            dispatch(showAlertSETTING())
            dispatch(setLocation(''))
            dispatch(setLocationType(''))
            dispatch(showAlertSETTINGCreate(true))
          } else {
            errorStatus.addMessage(
              'Event limit reached (Max limit - 3)',
              404,
              'error',
              false
            )
          }
        }

        dispatch(toggleRouteCast(false))
        break
      case 'add-location':
        dispatch(showAddLocationPopup())
        break
      case 'vertical-profile':
        addToTWS(<VerticalProfile key={idString}></VerticalProfile>)
        break
      case 'observations-mrms-radar':
        dispatch(
          setCurLayer({
            layer: WetDogWeatherLayersEnum.MRMS,
            mode: 'forecast',
          })
        )
        break
      case 'observations-myradar':
        dispatch(
          setCurLayer({
            layer: WetDogWeatherLayersEnum.RADAR,
            mode: 'forecast',
          })
        )
        break
      case 'decision-routcase':
        // dispatch(toggleRouteCast(true))
        dispatch(hideAlertSETTING())
        addToTWS(
          <div
            className="absolute top-0 left-0 w-full"
            key={'decision-routcast'}
          >
            <RouteCAST />
          </div>
        )
        break
      default:
        break
    }
    if (tileProducts.includes(id)) {
      setCurrTile(id)
      setCurrTileName(selection?.label)
      dispatch(setActiveBaseLayer('maps-road'))
    }
  }, [id, selectedItems, eventCount, selectedItemIds])

  return null
}

export default MenuSelection
