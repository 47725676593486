import { useEffect, useState } from 'react'
import chevronLeft from '../../assets/chevron-left.svg'
import chevronRight from '../../assets/chevron-right.svg'
import { TWS_API_URL } from '../../environment/apis.config'
import Trash from '../../assets/icons/bin.svg'
import moment from 'moment-timezone'
import { useDispatch, useSelector } from 'react-redux'
import { selectedItems } from '../../menus/SideNavigation/SideNavigationSlice';
import { setEventCount, showAlertSETTINGCreate } from '../../Components/Alerting/AlertingSlice/AlertingSlice'

const url = TWS_API_URL

// Get all the events
export async function getAllEvents() {
  try {
    const response = await fetch(url + '/events/user', {
      method: 'GET',
      headers: {
        // Authorization: `Bearer ` + sessionStorage.getItem('token'),
        Authorization: `Bearer ` + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    })
    const data = await response.json()
    return data
  }
  catch (error) {
    console.log(error)
  }
}

const SlideOverDialogEvents = () => {
  const [isVisible, setIsVisible] = useState(true)
  const [eventsList, setEventData] = useState([])
  const [dataFetched, setFlag] = useState(false)

  const handleButtonClick = () => {
    setIsVisible(!isVisible)
  }
  const dispatch = useDispatch()

  const eventCount = useSelector(
    (state: RootState) => state.alert.eventCount
  )

  const deleteEvent = async (index: any) => {
    if (confirm('Delete this Event?')) {
      let eventName = eventsList[index]["eventName"]
      setEventData(eventsList.filter((event, i) => i !== index));
      const response = await fetch(url + '/events', {
        method: 'DELETE',
        headers: {
          // Authorization: `Bearer ` + sessionStorage.getItem('token'),
          Authorization: `Bearer ` + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          events: [eventName]
        }),
      })
      const data = await response.json()
      dispatch(selectedItems('Events List'))
      dispatch(selectedItems('Alerts'))
      dispatch(selectedItems('Alerts'))
      dispatch(setEventCount(eventCount-1))
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      let data = await getAllEvents()
      setEventData(data)
      setFlag(true)
    }
    fetchData()
      // make sure to catch any error
      .catch(console.error);;
  }, [])

  return (
    <div className="h-full flex">
      <div className="flex absolute z-[9999]" style={{ minWidth: "3em" }}>
        <div className="flex">
          <div
            className={` ${isVisible ? 'opacity-1 scale-100' : 'hidden opacity-0 scale-0'
              } bg-white w-15rem p-4 `}
          >
            {isVisible && (
              <div className="relative overflow-x-auto rounded text-sm">
                <table className="w-full text-left">
                  <thead className="uppercase bg-gray-400">
                    <tr>
                      <th scope="col" className="p-1">
                        Event
                      </th>
                      <th scope="col" className="p-1">
                        Start Time
                      </th>
                      <th scope="col" className="p-1">
                        End Time
                      </th>
                      <th scope="col" className="p-1">

                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      dataFetched && eventsList.length > 0 ?
                        eventsList.map((event, index) => (
                          <tr className="bg-white border-b" key={event['eventID']}>
                            <td scope="row" className="p-1 border border-slate-600">
                              {event["eventName"]}
                            </td>
                            <td className="p-1 border border-slate-600">
                              {moment.utc(event["startTime"]).tz(moment.tz.guess()).format("MMM D YYYY, HH:mm:ss")}
                            </td>
                            <td className="p-1 border border-slate-600">
                              {moment.utc(event["endTime"]).tz(moment.tz.guess()).format("MMM D YYYY, HH:mm:ss")}
                            </td>
                            <td className="p-1 border border-slate-600">
                              <button onClick={() => deleteEvent(index)} className="block w-6 h-6"> <img src={Trash} alt="" /> </button>
                            </td>
                          </tr>
                        ))
                        :
                        <tr className="bg-white border-b">
                          <td scope="row" className="p-3 border border-slate-600">
                            <div className="inline-block w-16 h-4 animate-pulse rounded bg-gray-400" />
                          </td>
                          <td className="p-3 border border-slate-600">
                            <div className="inline-block w-16 h-4 animate-pulse rounded bg-gray-400" />
                          </td>
                          <td className="p-3 border border-slate-600">
                            <div className="inline-block w-16 h-4 animate-pulse rounded bg-gray-400" />
                          </td>
                          <td className="p-3 border border-slate-600">
                            <div className="inline-block w-16 h-4 animate-pulse rounded bg-gray-400" />
                          </td>
                        </tr>
                    }
                  </tbody>
                </table>
                {dataFetched && eventsList.length == 0 &&
                  <div className="p-4 my-2 text-sm text-blue-800 rounded-lg bg-blue-50">
                    No active events present at this time !!
                  </div>
                }
              </div>
            )}
          </div>
          <div>
            <button
              className="bg-gray-800 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded-tr-2xl rounded-br-2xl"
              onClick={handleButtonClick}
            >
              <div className="[writing-mode:vertical-lr] flex justify-center items-center flex-row">
                <span className="tracking-wider text-center p-1">
                  Event List
                </span>
                <img
                  alt="chevron"
                  src={` ${isVisible ? chevronLeft : chevronRight}`}
                />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SlideOverDialogEvents
