import Radar from '../../../assets/radar.gif'
import TWSLogo from '../../../assets/TWS-Logo.svg'
import { useState } from 'react'
import { TWS_API_URL } from '../../../environment/apis.config'
import Toast, { toastPosition, toastType } from '../../Toast/Toast'
import { Link, useNavigate } from 'react-router-dom'
import { Footer } from '../../Footer/Footer'
export default function ForgotPasswordPage() {
  const [email, setEmail] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [type, setToastType] = useState(toastType.success)
  // const dispatch = useDispatch()
  // const navigate = useNavigate()

  const toastCallbackFn = () => {
    setShowToast(false)
    setToastMessage('')
  }
  const handleSubmit = () => {
    fetch(`${TWS_API_URL}/client/forgot-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    })
      .then((response) => {
        if (response.status === 400) {
          throw new Error('User not found')
        }
        return response.json()
      })
      .then((data) => {
        setToastMessage(data.message)
        setShowToast(true)
      })
      .catch((error) => {
        setToastType(toastType.error)
        setToastMessage(error.message)
        setShowToast(true)
      })
  }

  return (
    <div className="flex flex-col justify-center items-center h-screen w-full bg-white">
      <div className="w-25 flex flex-row p-6 bg-white ">
        <div className="flex flex-col justify-center w-1/2 m-6">
          <img src={Radar} alt="GIF" className="p-2 m-2 h-38" />
          <img src={TWSLogo} alt="Logo" className="m-2 p-2 h-20" />
        </div>
        <div className="flex flex-col w-1/2  ">
          <div className="justify-self-center mt-8">
            <h2 className="text-center text-2xl font-bold  tracking-wider">
              Forgot Password
            </h2>
          </div>
          <div className='mt-40'>          
            <span className='text-md'>Enter the email address associated with your account and we'll send you a link to reset your account.</span>
          </div>  
          <div className="mt-12">
            <label
              className="block text-gray-700 text-md tracking-wide font-bold mb-2"
              htmlFor="email"
            >
              Email Address
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
              id="email"
              type="email"
              placeholder="test@test.com"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="text-right text-blue-500 my-4 hover:underline tracking-wide mt-12">
              <Link to="/login">Back to Login</Link>
              </div>
          <div className="flex items-center justify-between mt-4">
            <button
              className="bg-green-500 w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={handleSubmit}
            >
              Reset Password
            </button>
          </div>
          <div className="text-right text-blue-500 my-4 hover:underline tracking-wide mb-12 mt-10">
              <Link to="/signup">Don't have an account? Signup</Link>
              </div>

         
      

        
        </div>
      </div>
<Footer/>
      {showToast ? (
        <Toast
          toastMessage={toastMessage}
          toastPosition={toastPosition.bottomCenter}
          toastType={type}
          onClickCallback={toastCallbackFn}
        />
      ) : (
        <div></div>
      )}
    </div>
  )
}
