
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchSIGMETData } from "./SIGMETAPI";

import { SigmetParsedData } from "../../interfaces/sigmet";

  const initialState = {
    data: [] as SigmetParsedData[],
    status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
    error: null,
  };
  export const getSigmetData = createAsyncThunk(
    'location/addNewLocation',
    async () => {
      const response = await fetchSIGMETData()
      return response
    }
  )
  
  const sigmetSlice = createSlice({
    name: 'sigmet',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getSigmetData.pending, (state) => {
          state.status = 'loading';
          state.error = null;
        })
        .addCase(getSigmetData.fulfilled, (state, action) => {
          state.status = 'succeeded';
        })
        .addCase(getSigmetData.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });
    },
  });
  
  export default sigmetSlice.reducer;