import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import TWSLogo from '../../../assets/TWS-Logo.svg'
import Radar from '../../../assets/radar.gif'
import { useForm } from 'react-hook-form'
import { TWS_API_URL } from '../../../environment/apis.config'
import Toast, { toastPosition, toastType } from '../../Toast/Toast'
import { RegisterUser } from '../UserSlice'
import { RegisterPayload, User } from '../../../interfaces/user'
import { Link } from 'react-router-dom'
import { Footer } from '../../Footer/Footer'

export default function RegisterPage() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onSubmit',
  })
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const toastCallbackFn = () => {
    setShowToast(false)
    setToastMessage('')
  }

  const onSubmit = async (data) => {
    const userPayload: RegisterPayload = {
      isAdmin: false,
      ...data,
    }
    const user = await dispatch(RegisterUser(userPayload))
    if (user?.payload?.token != undefined) {
      const userData: User = {
        ...user.payload,
        isAuthenticated: true,
      }
      // sessionStorage.setItem('token', user.payload.token)
      localStorage.setItem('token', user.payload.token)
      // sessionStorage.setItem('userId', user.payload.id)
      localStorage.setItem('userId', user.payload.id)
      if (
        user.payload.reseller === null ||
        user.payload.reseller.reseller === 'default'
      ) {
        const url = `${TWS_API_URL}/payment/` + userData.id
        window.location.href = url
      } else {
        navigate('/map')
      }
    } else {
      setToastMessage(user?.error?.message || 'Something went wrong')
      setShowToast(true)
    }
  }
  const onError = (errors, e) => {
    setShowToast(true)
    setToastMessage("Please check the Form for errors and try again")
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen w-full">
      <div className="w-25 mb-10 flex flex-row p-6 rounded">
        <div className="flex flex-col justify-center w-1/2 m-6">
          <img src={Radar} alt="GIF" className="p-2 m-2 h-45" />
          <img src={TWSLogo} alt="Logo" className="m-2 p-2 h-20" />
        </div>
        <div className="flex flex-col justify-center content-center w-1/2 h-50 my-12">
          <div className="justify-center">
            <h2 className="text-center text-2xl font-bold mb-2 tracking-wider">
              Create an account
            </h2>
            <form onSubmit={handleSubmit(onSubmit,onError)}>
              <div className="flex flex-row gap-3">
                <div className="mb-1 w-1/5">
                  <label
                    className="block text-gray-700 text-md font-bold mb-1 tracking-wide"
                    htmlFor="title"
                  >
                    Title
                  </label>
                  <input
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="title"
                    type="text"
                    placeholder="Mr."
                    {...register('title')}
                  />
                </div>
                <div className="mb-1 grow w-2/5 relative">
                  <label
                    className="block text-gray-700 text-md font-bold mb-1 tracking-wide"
                    htmlFor="firstName"
                  >
                    First Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    className={`appearance-none border ${errors.firstname ? 'border-red-500' : 'rounded'} w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                    id="firstName"
                    type="text"
                    placeholder="Anakin"
                    {...register('firstname', {
                      required: 'First Name is required',
                    })}
                    title={errors.firstname ? errors.firstname.message : ''}
                  />
                  {errors.firstname && (
                      <svg
                      className="absolute right-3 top-10 h-5 w-5 text-red-500" // Tailwind class for size, adjust as needed
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="16" cy="16" r="16" className="fill-red-600" />
                      <path
                        d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z"
                        className="fill-gray-200"
                      />
                    </svg>
                  )}
                  {errors.firstname && (
                    <p className="text-red-500 before:content-['⚠'] hidden">
                      {errors.firstname.message}
                    </p>
                  )}
                </div>
                <div className="mb-1 grow w-2/5 relative">
                  <label
                    className="block text-gray-700 text-md font-bold mb-1 tracking-wide"
                    htmlFor="lastName"
                  >
                    Last Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    className={`appearance-none border ${errors.lastname ? 'border-red-500' : 'rounded'} w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                    id="lastName"
                    type="text"
                    placeholder="Skywalker"
                    {...register('lastname', {
                      required: 'Last Name is required',
                    })}
                    title={errors.lastname ? errors.lastname.message : ''}
                  />
                  {errors.lastname && (
                    <svg
                      className="absolute right-3 top-10 h-5 w-5 text-red-500" // Tailwind class for size, adjust as needed
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="16" cy="16" r="16" className="fill-red-600" />
                      <path
                        d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z"
                        className="fill-gray-200"
                      />
                    </svg>
                  )}
                  {/* Optionally, adjust the visibility or styling of the inline error message. */}
                  {errors.lastname && (
                    <p className="text-red-500 before:content-['⚠'] hidden">
                      {errors.lastname.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="mb-1 mt-2 relative">
                <label
                  className="block text-gray-700 text-md font-bold mb-1 tracking-wide"
                  htmlFor="companyName"
                >
                  Company Name <span className="text-red-500">*</span>
                </label>
                <input
                  className={`appearance-none border ${errors.company ? 'border-red-500' : 'rounded'} w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="companyName"
                  type="text"
                  placeholder="ACME Corporation"
                  {...register('company', {
                    required: 'Company is required',
                  })}
                  title={errors.company ? errors.company.message : ''}
                />
                {errors.company && (
                  <svg
                    className="absolute right-3 top-10 h-5 w-5 text-red-500" // Adjust the top value as needed for alignment
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="16" cy="16" r="16" className="fill-red-600" />
                    <path
                      d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z"
                      className="fill-gray-200"
                    />
                  </svg>
                )}
                {/* Optionally, you can adjust the visibility or styling of the inline error message. */}
                {errors.company && (
                  <p className="text-red-500 before:content-['⚠'] hidden">
                    {errors.company.message}
                  </p>
                )}
              </div>
              <div className="mb-1 mt-2 relative">
                <label
                  className="block text-gray-700 text-md font-bold mb-1 tracking-wide"
                  htmlFor="address"
                >
                  Address
                </label>
                <input
                  className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="address"
                  type="text"
                  placeholder="1640 Riverside Drive, Hill Valley, CA"
                  {...register('address')}
                />
              </div>
              <div className="flex flex-row gap-6 mt-4">
              <div className="mb-1 w-1/2 relative">
                    <label
                      className="block text-gray-700 text-md font-bold mb-1 tracking-wide"
                      htmlFor="phoneNumber"
                    >
                      Primary Phone Number <span className="text-red-500">*</span>
                    </label>
                    <input
                      className={`appearance-none border ${errors.primary ? 'border-red-500' : 'rounded'} w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                      id="phoneNumber"
                      type="text"
                      placeholder="(123) 456-7890"
                      {...register('primary', {
                        required: 'Phone number is required',
                      })}
                      title={errors.primary ? errors.primary.message : ''}
                    />
                    {errors.primary && (
                      <svg
                        className="absolute right-3 top-10 h-5 w-5 text-red-500" // Adjust the top value as needed for alignment
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="16" cy="16" r="16" className="fill-red-600" />
                        <path
                          d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z"
                          className="fill-gray-200"
                        />
                      </svg>
                    )}
                    {/* Optionally, you can adjust the visibility or styling of the inline error message. */}
                    {errors.primary && (
                      <p className="text-red-500 before:content-['⚠'] hidden">
                        {errors.primary.message}
                      </p>
                    )}
                  </div>
                <div className="mb-1 w-1/2">
                  <label
                    className="block text-gray-700 text-md font-bold mb-1 tracking-wide"
                    htmlFor="altPhoneNumber"
                  >
                    Secondary Phone Number
                  </label>
                  <input
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="altPhoneNumber"
                    type="text"
                    placeholder="(123) 456-7890"
                    {...register('secondary')}
                  />
                </div>
              </div>
              <div className="mb-1 mt-2 relative">
                <label
                  className="block text-gray-700 text-md font-bold mb-1 tracking-wide"
                  htmlFor="email"
                >
                  Email Address <span className="text-red-500">*</span>
                </label>
                <input
                  className={`appearance-none border ${errors.email ? 'border-red-500' : 'rounded'} w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="email"
                  type="email"
                  placeholder="test@test.com"
                  {...register('email', {
                    required: 'Email address is required',
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: 'Please enter a valid email address',
                    },
                  })}
                  title={errors.email ? errors.email.message : ''}
                />
                {errors.email && (
                  <svg
                    className="absolute right-3 top-10 h-5 w-5 text-red-500" // Adjust the top value as needed for alignment
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="16" cy="16" r="16" className="fill-red-600" />
                    <path
                      d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z"
                      className="fill-gray-200"
                    />
                  </svg>
                )}
                {/* Optionally, you can adjust the visibility or styling of the inline error message. */}
                {errors.email && (
                  <p className="text-red-500 before:content-['⚠'] hidden">
                    {errors.email.message}
                  </p>
                )}
              </div>
              <div className="mb-1 mt-4 relative">
                <label
                  className="block text-gray-700 text-md font-bold mb-1 tracking-wide"
                  htmlFor="password"
                >
                  Password <span className="text-red-500">*</span>
                </label>
                <input
                  className={`appearance-none border ${errors.password ? 'border-red-500' : 'rounded'} w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="password"
                  type="password"
                  placeholder="abcd@123"
                  {...register('password', {
                    required: 'Password is required',
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                      message:
                        'Password must contain Minimum eight characters, at least one uppercase letter, one lowercase letter, one special character, and one number',
                    },
                  })}
                  title={errors.password ? errors.password.message : ''}
                />
                {errors.password && (
                  <svg
                    className="absolute right-3 top-10 h-5 w-5 text-red-500" // Adjust the top value as needed for alignment
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="16" cy="16" r="16" className="fill-red-600" />
                    <path
                      d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z"
                      className="fill-gray-200"
                    />
                  </svg>
                )}
                {/* Optionally, you can adjust the visibility or styling of the inline error message. */}
                {errors.password && (
                  <p className="text-red-500 before:content-['⚠'] hidden">
                    {errors.password.message}
                  </p>
                )}
              </div>
              <div className="mb-1 mt-4">
                <label
                  className="block text-gray-700 text-md font-bold mb-1 tracking-wide"
                  htmlFor="coupon"
                >
                  Coupon
                </label>
                <input
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="coupon"
                  type="text"
                  placeholder=""
                  {...register('coupon')}
                />
              </div>

              <div className="text-right text-blue-500 mb-1 hover:underline">
                <Link to="/login">Sign in instead.</Link>
              </div>
              <div className="flex items-center justify-between">
                <button className="bg-green-500 w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline tracking-wide text-md">
                  Register
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer/>
    
      {showToast ? (
        <Toast
          toastMessage={toastMessage}
          toastPosition={toastPosition.topRight}
          toastType={toastType.error}
          onClickCallback={toastCallbackFn}
        />
      ) : (
        <div></div>
      )}
    </div>
  )
}
