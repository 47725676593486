
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchTAFData } from "./TAFAPI";
import { TAFParsedData } from "../../interfaces/taf";

  const initialState = {
    data: [] as TAFParsedData[],
    status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
    error: null,
  };
  export const getTafData = createAsyncThunk(
    'location/addNewLocation',
    async () => {
      const response = await fetchTAFData()
      return response
    }
  )
  
  const tafSlice = createSlice({
    name: 'taf',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getTafData.pending, (state) => {
          state.status = 'loading';
          state.error = null;
        })
        .addCase(getTafData.fulfilled, (state, action) => {
          state.status = 'succeeded';
        })
        .addCase(getTafData.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });
    },
  });
  
  export default tafSlice.reducer;