import LegendContent from './LegendContent'
import './legend.css'
import { useSelector } from 'react-redux'

//
// Does not handle the actual logic for generating the legend. Go to LegendContent.tsx for that.
//

function Legend({ colorMap, units }) {
  const darkMode = useSelector((state) => state.mapState.darkMode)

  return (
    <>
      <div
        className="w-56 flex flex-col text-center items-center justify-center p-2 z-[9999]
        absolute right-0 top-0"
      >
        <div
          className={`font-semibold text-base ${
            !darkMode ? 'text-black' : 'text-white'
          }`}
        >
          Legend
        </div>
        <LegendContent colorMap={colorMap} units={units} />
      </div>
    </>
  )
}

export default Legend
