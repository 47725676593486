import { useContext, ReactElement, useState } from "react";
import TWSContext from './TWSMapContext'

/**
 * Custom React hook to manage elements in the TWS map.
 *
 * @returns {Object} An object containing the following properties and functions:
 *   - twsElements: An array of elements currently present in the TWS map.
 *   - addToTWS: A function to add an element to the TWS map.
 *     @param {Object} element - The element to be added to the TWS map. It must have a unique key property.
 *   - removeFromTWS: A function to remove an element from the TWS map.
 *     @param {Object} element - The element to be removed from the TWS map. It must have a unique key property.
 */
export default function useTWSMapContext() {
  const context = useContext(TWSContext)


  if (!context) {
    throw new Error('useTWSMapContext must be used within a TWSProvider')
  }

  const { twsElements, setTwsElements } = context

  /**
   * Add an element to the TWS map.
   *
   * @param {Object} element - The element to be added to the TWS map. It must have a unique key property.
   */
  const addToTWS = (element: ReactElement) => {
    if (element.key == null) {
      throw new Error('Element passed to addToTWS must have a key')
    }
    if (element.key in twsElements) {
      return
    }
    setTwsElements((prevTwsElements) => [...prevTwsElements, element])
  }

  /**
   * Remove an element from the TWS map.
   *
   * @param {Object|string|number} element - The element or its key value to be removed from the TWS map.
   */
  const removeFromTWS = (elementKey: React.Key) => {
    setTwsElements((prevTwsElements) =>
      prevTwsElements.filter((el) => el.key !== elementKey)
    )
  }

  return { twsElements, addToTWS, removeFromTWS }
}
