import { CSSProperties, ReactNode } from 'react'


type Props = {
  style?: CSSProperties
  className?: string
  children?: ReactNode
}
export default function MainContainer(props: Props) {
  const { style, children } = props
  return (
    <div className="flex basis-auto flex-grow shrink bg-white" style={style}>
      {children}
    </div>
  )
}
