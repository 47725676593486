import { TWS_API_URL } from '../../environment/apis.config'
import { TAFAPIParser } from '../../utils/TAFUtils'
import { timeouts } from '../RequestTimeouts'

const API_ENDPOINT = TWS_API_URL + '/taf'

// export const fetchTAFData = createAsyncThunk('taf/fetchTAF', async (_, thunkAPI) => {



  export const fetchTAFData = async (payload) => {
    const controller = new AbortController()

    return new Promise(async (resolve, reject) => {
      const timeoutId = setTimeout(() => {
        controller.abort()
        reject(new Error('TAF Request timed out. Please try again.'))
      }, timeouts.taf)
  
    let res = await fetch(
      `${API_ENDPOINT}?lat=${payload.latitude}&long=${payload.longitude}&distance=100`,
      {
        method: 'GET',
        headers: {
          // Authorization: `Bearer ` + sessionStorage.getItem('token'),
          Authorization: `Bearer ` + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
        signal: controller.signal,
      }
    )
    .then(res => {
      clearTimeout(timeoutId)
      if (!res.ok) {
        throw new Error('Failed to fetch TAF data')
      }
      return res.json()
    })
    .then(data => {
      resolve(TAFAPIParser(data))
    })
    .catch(error => {
      if (error.name === 'AbortError') {
        reject(new Error('TAF Request timed out. Please try again.'))
      } else {
        reject(error)
      }
    })
    })
  }

