import { User, LoginPayload, RegisterPayload, UpdateProfilePayload } from '../interfaces/user'
import { TWS_API_URL } from '../environment/apis.config'
import useErrorStatus from '../hooks/UseErrorStatus'

export const fetchUserDetails = (): Promise<User> => {
  return new Promise((resolve, reject) => {
    // const token = sessionStorage.getItem('token')
    const token = localStorage.getItem('token')
    const url = `${TWS_API_URL}/client/me`
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
     
    })
      .then((response) => {
        if (response.ok) {
          return response.json().then((data) => {
            resolve(data); // Resolve with the JSON data
          });
        } else {
          reject(new Error('Request failed')); // Reject on non-ok response
        }
      })
      .catch((error) => reject(error))
  })
}

export const Login = (payload: LoginPayload): Promise<User> => {
  return new Promise((resolve, reject) => {
    const url = `${TWS_API_URL}/auth/client/login`
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
      .then((response) => resolve(response.json()))
      .catch((error) => reject(error))
  })
}

export const UpdatePassword = (payload) => {
  // const errorStatus = useErrorStatus()
  return new Promise((resolve, reject) => {
    const url = `${TWS_API_URL}/client/update-password`
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then((response) => {
      if(!response.ok){
        reject(new Error('Request failed'))
      }
      else
        resolve(response.json())
    })
    .catch((error) => {});
  })
}

export const UpdateProfile = (payload: UpdateProfilePayload)  => {
  return new Promise((resolve, reject) => {
    const url = `${TWS_API_URL}/customUserProfile`
    fetch( url, {
      method: 'PUT',
      body: JSON.stringify(payload)
    })
    .then((response) => resolve(response.json()))
    .catch((error) => reject(error))
  })
}

export const Register = (payload: RegisterPayload) => {
  return new Promise((resolve, reject) => {
    let url = `${TWS_API_URL}/auth/client/register`
    if (payload.coupon && payload.coupon != '') {
      url = `${TWS_API_URL}/auth/client/registerSpecialUser`
    }

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(async (response) => {
        if (response.status === 200) {
          resolve(response.json())
        } else {
          let res = await response.json()
          reject(res)
        }
      })
      .catch((error) => {
        reject(error)})
  })
}

export const UpdateContact =async (userId:string, firstname:string, lastname:string, primary:string) => {
  const updateContactDetails = {"user":{"firstname": firstname,
                                "lastname":lastname,
                                // "email":email,
                                "primary":primary}}
  const url = `${TWS_API_URL}/dashboardClientSettings`
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        firstname: firstname,
        lastname: lastname,
        // email: email,
        primary: primary
      }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error('Error fetching user thresholds');
  }
}
