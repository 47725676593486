import SigmetData from '../interfaces/sigmet'

interface WeatherData {
  [key: string]: {
    value: any
    units?: string
  }
}

interface SigmetParsedData {
  coordinates?: [[]]
  properties?: { [key: string]: any }
  color?: string
}
export function createPopUpKeyValuePair(
  data: WeatherData
): { key: string; value: any }[] {
  return Object.entries(data).map(([key, value]) => ({
    key,
    value: value.value,
  }))
}

export function SigmetAPIParser(apiresponse: SigmetData) {
  if (apiresponse.features.message) {
    throw new Error(apiresponse.features.message)
  }
  const parsedArray: SigmetParsedData[] = []
  apiresponse?.features?.forEach((feature) => {
    if (feature.geometry.type === 'Point') {
      const reverse = feature.geometry.coordinates
      const temp = reverse[0]
      reverse[0] = reverse[1]
      reverse[1] = temp
      const newObject: SigmetParsedData = {
        coordinates: reverse,
        properties: feature.properties,
        color: feature.properties.hazard,
      }
      parsedArray.push(newObject)
    } else if (feature.geometry.type === 'Polygon') {
      const saved: SigmetParsedData | number[] = []
      feature.geometry.coordinates.forEach((latlng, index) => {
        for (let index = 0; index < latlng.length; index++) {
          const temp = latlng[index][0]
          latlng[index][0] = latlng[index][1]
          latlng[index][1] = temp
        }
        saved.push(latlng)
      })
      const newObject: SigmetParsedData = {
        coordinates: saved,
        properties: feature.properties,
        color: feature.properties.hazard,
      }
      parsedArray.push(newObject)
    }
  })
  return parsedArray
}
