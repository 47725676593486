import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchUserThresholds, updateThreshold, deleteThreshold } from './thresholdsApi';

interface UpdateThresholdPayload {
  parameterName: string;
  updatedThreshold: any; 
}
export const fetchThresholds = createAsyncThunk('thresholds/fetchThresholds', async () => {
  const data = await fetchUserThresholds();
  return data;
});

export const updateThresholdValue = createAsyncThunk(
  'thresholds/updateThresholdValue',
  async ({ parameterName, updatedThreshold }:UpdateThresholdPayload) => {
    const updatedData = await updateThreshold(parameterName, updatedThreshold);
    return updatedData;
  }
);

export const deleteThresholdValue = createAsyncThunk('thresholds/deleteThresholdValue', async ( parameter:string ) => {
  await deleteThreshold(parameter);
});

