import { createSlice } from "@reduxjs/toolkit";

interface ILayer {
  mode: string;
  layer: number;
}

interface IWetDogWeatherProducts {
  curLayer: ILayer
  curLevel: string | undefined
}

const initialState: IWetDogWeatherProducts = {
  curLayer: {
    layer: -1,
    mode: '',
  },
  curLevel: undefined,
}

const WetDogWeatherSlice = createSlice({
  name: 'WetDogWeather',
  initialState,
  reducers: {
    setCurLayer(state, action) {
      state.curLayer = action.payload
    },
    setCurLevel(state, action) {
      state.curLevel = action.payload
    },
  },
})

export const { setCurLayer, setCurLevel } = WetDogWeatherSlice.actions
export default WetDogWeatherSlice.reducer
