import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// interface MapState {
//   waypoints: string;
// }

// Define BaseLayer type
export type BaseLayer = 'maps-road' | 'maps-satellite' | 'maps-sectional'

interface MapState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  drawnItems: any
  waypoints: string
  activeBaseLayer: BaseLayer
  previousBaseLayer: BaseLayer
  darkMode: boolean
  baseLayerUtil: string[]
}

const initialState: MapState = {
  drawnItems: null,
  waypoints: '',
  activeBaseLayer: 'maps-road',
  previousBaseLayer: 'maps-road',
  darkMode: false,
  baseLayerUtil: ['maps-road','maps-road']
}

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setDrawnItems: (state, action) => {
      state.drawnItems = action.payload
    },
    clearDrawnItems: (state) => {
      state.drawnItems = null
    },
    setWaypoints: (state, action: PayloadAction<string>) => {
      state.waypoints = action.payload
    },
    setActiveBaseLayer: (state, action: PayloadAction<BaseLayer>) => {
      state.previousBaseLayer = state.activeBaseLayer
      state.activeBaseLayer = action.payload
    },
    enableDarkMode: (state, action: PayloadAction<boolean>) => {
      state.darkMode = action.payload
    },
    changBaseLayers: (state, action: PayloadAction<string>) => {
      state.baseLayerUtil[0] =  state.activeBaseLayer
      state.baseLayerUtil[1] = action.payload
    }
  },
})

export const {
  setDrawnItems,
  clearDrawnItems,
  setWaypoints,
  setActiveBaseLayer,
  enableDarkMode,
  changBaseLayers,
} = mapSlice.actions
export default mapSlice.reducer
