import { useState } from 'react'
import { elements } from '../../../interfaces/alertingelements'

const EventBox = (props: Props) => {
  const products = props.properties.products
  const [selectedProducts, setSelectedProducts] = useState([])

  const handleProductClick = (product) => {
    if (selectedProducts.includes(product)) {
      setSelectedProducts(selectedProducts.filter((p) => p !== product))
    } else {
      setSelectedProducts([...selectedProducts, product])
    }
  }
  const getColorForProduct = (productValue) => {
    const product = elements.find(
      (element) => element.edit === productValue.hazard
    )
    return '#' + product.color
  }
  const isProductDisabled = (productHazard) => {
    return (
      productHazard === 'Lightning' ||
      productHazard === 'NWS Tornado Warning' ||
      productHazard === 'NWS Severe Thunderstorm Warning'
    )
  }

  return (
    <>
      <div
        className="bg-black text-white flex items-center text-sm"
        style={{ padding: '5px' }}
      >
        <span className="font text-lg">Event Details</span>
      </div>
      <hr />
      <div className="p-2 text-center text-sm">
        Event Name: {props.properties.eventName}
      </div>
      <hr />
      <div className="p-2 text-center text-sm">
        Start Time: {props.properties.episodeStartTime}
      </div>
      <hr />
      <div className="p-2 text-center text-sm">
        End Time: {props.properties.episodeEndTime}
      </div>
      <hr />
      <div
        className="p-2 text-center text-sm"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        {products.map((product, index) => (
          <div key={index}>
            <button
              onClick={() => handleProductClick(product)}
              className={`w-full h-full font-bold overflow-hidden z-[900] focus:outline-none product-button rounded-full p-2 ${
                selectedProducts.includes(product) ? 'selected' : ''
              } text-black text-opacity-75`}
              style={{
                backgroundColor: getColorForProduct(product),
                marginBottom: '10px',
              }}
            >
              {product.hazard}
            </button>
            <hr className="mb-1" />
            {selectedProducts.includes(product) && (
              <div className="mb-5">
                {product.hazard !== 'Lightning' &&
                product.hazard !== 'NWS Tornado Warning' &&
                product.hazard !== 'NWS Severe Thunderstorm Warning' ? (
                  <div>
                    <div className="p-2 text-center text-sm">
                      Threshold: {product?.parameters[0]?.comparison} {parseFloat(product?.parameters[0]?.threshold).toFixed(2)} {product?.parameters[0]?.thresholdUnits}
                    </div>
                    <hr />
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="p-2 text-center text-sm">
                  Distance: {product?.parameters[0]?.distance} {product?.parameters[0]?.distanceUnits}
                </div>
                <hr />
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default EventBox
