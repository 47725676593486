import { useEffect, useRef, useState } from 'react'
import {
  getCustomProfile,
  putCustomProfile,
} from '../../../api/CustomProfilesAPIService'
import MainNavMenu from '../../../menus/MainNavMenu/MainNavMenu'
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, updateContactDetails, updateCustomUnits } from '../../Users/UserSlice'
import { UpdatePassword } from '../../../services/UserApiService';
import { RootState } from '../../../store';
import { toastType } from '../../Toast/Toast';
import useErrorStatus from '../../../hooks/UseErrorStatus';

function updateCustomProfiles(
  tempUnit: string,
  windSpeedUnit: string,
  precipRateUnit: string
) {
  const payload = {
    temperature: tempUnit,
    wind_speed: windSpeedUnit,
    precipitationRate: precipRateUnit,
  }
  
  return putCustomProfile(payload)
}

function validateString(s: string): boolean {
  // Check the length of the string
  if (s.length < 8) {
      return false;
  }

  // Check for at least 1 uppercase letter
  if (!/[A-Z]/.test(s)) {
      return false;
  }

  // Check for at least 1 lowercase letter
  if (!/[a-z]/.test(s)) {
      return false;
  }

  // Check for at least 1 special character
  if (!/[\W_]/.test(s)) {
      return false;
  }

  // Check for at least 1 number
  if (!/[0-9]/.test(s)) {
      return false;
  }

  return true;
}

function UserProfile() {
  const [selectedTemperatureUnit, setSelectedTemperatureUnit] = useState('C')
  const [selectedWindSpeedUnit, setSelectedWindSpeedUnit] = useState('KTS')
  const [selectedPrecipRateUnit, setSelectedPrecipRateUnit] = useState('MM/HR')
  const [isLoading, setIsLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [userFirstName, setUserFirstName] = useState('')
  const [userLastName, setUserLastName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userMobile, setUserMobile] = useState('')
  const dispatch = useDispatch()
  const clientid = useSelector((state: RootState) => state.user.id)
  const [editUserSettings, setEditUserSettings] = useState(false)
  const errorStatus = useErrorStatus()

  const oldPasswordRef = useRef<HTMLInputElement>(null)
  const newPasswordRef = useRef<HTMLInputElement>(null)
  const confirmNewPasswordRef = useRef<HTMLInputElement>(null)
  const selectedWindSpeedUnitRef = useRef(null)
  const selectedTemperatureUnitRef = useRef(null)
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [toastColor, setToastType] = useState(toastType.info)
  const [newPwdError, setNewPwdError] = useState(false)
  const [oldPwdError, setOldPwdError] = useState(false)
  const [confirmNewPwdError, setConfirmNewPwdError] = useState(false)

  // const userId = sessionStorage.getItem('userId')
  const userId = localStorage.getItem('userId')
  const user = useSelector(state => state.user)
  const selectedPrecipRateUnitRef = useRef(null)
  const statusError = useErrorStatus()
  const customUnits = useSelector((state: RootState) => state.user.customUnits)
  useEffect(() => {
    if (user) {
      setUserFirstName(user.firstname)
      setUserLastName(user.lastname)
      setUserEmail(user.email)
      setUserMobile(user.primary)
      const customprofile = getCustomProfile(true)

      customprofile
        .then((res) => {
          const units = res?.customProfile?.units
          if (units && units.temperature)
            setSelectedTemperatureUnit(units?.temperature)
          if (units && units.wind_speed)
            setSelectedWindSpeedUnit(units?.wind_speed)
          if (units && units.precipitationRate)
            setSelectedPrecipRateUnit(units?.precipitationRate)
          setIsLoading(false)
        })
        .catch((err) => {
          console.log(err)
        })
        if (editUserSettings) {
          statusError.addMessage('User contact details updated successfully',200,"success",false)
          setEditUserSettings(false)
        }
    }
  }, [user])

  useEffect(() => {
    if (newPasswordRef.current !== null) {
      // adding the new-password attribute to the password
      // this doesn't seem to work for me, but the council of wise elders say to do it.
      newPasswordRef.current.setAttribute("autocomplete", "new-password")
    }
    if (confirmNewPasswordRef.current !== null) {
      confirmNewPasswordRef.current.setAttribute("autocomplete","new-password")
    }
    if (oldPasswordRef.current !== null) {
      oldPasswordRef.current.setAttribute("autocomplete","new-password")
    }
  },[])

  const handleChangePassword = () => {
    const oldPwd = oldPasswordRef.current !== null ? oldPasswordRef.current.value : ''
    const newPwd = newPasswordRef.current !== null ? newPasswordRef.current.value : ''
    const confirmNewPwd = confirmNewPasswordRef.current !== null ? confirmNewPasswordRef.current.value : ''

    if (oldPwd === '' || newPwd === '' || confirmNewPwd === '') {
      const msg = oldPwd ==='' ? 'Please enter your old password' : newPwd === '' ? 'Please enter your new password' : 'Please enter your confirm new password'
      
      setToastMessage(msg)
      errorStatus.addMessage(msg,200,toastType.error,true)
    } else {
      if (!validateString(newPwd) || !validateString(confirmNewPwd)) {
        errorStatus.addMessage('New password must contain Minimum eight characters at least one uppercase letter, one lowercase letter, one special character.',200,toastType.error,true)
        setNewPwdError(true)
        setTimeout(() => {setNewPwdError(false)}, 7000)
      } else {
        if (oldPasswordRef.current !== null && newPasswordRef.current !== null && confirmNewPasswordRef.current !== null){
          if (newPasswordRef.current.value === confirmNewPasswordRef.current.value){
            UpdatePassword({
              clientId:clientid,
              oldPassword: oldPasswordRef.current.value, 
              newPassword: newPasswordRef.current.value
            })
            .then( response => {
              if (response.message === 'Password successfully updated.') {

                newPasswordRef.current !== null ? newPasswordRef.current.value = '' : null
                oldPasswordRef.current !== null ? oldPasswordRef.current.value = '' : null
                confirmNewPasswordRef.current !== null? confirmNewPasswordRef.current.value = '' : null
                
                errorStatus.addMessage(response.message,200,toastType.success,false)
              }
              else {
                errorStatus.addMessage('Old password is incorrect',200,toastType.error,true)
              }
            })
            .catch(error => {
              errorStatus.addMessage("Error updating password",200,toastType.error,true)
            })
          } else {
            errorStatus.addMessage('New passwords do not match.',200,toastType.error,true)
            setConfirmNewPwdError(true)
            setTimeout(() => {setConfirmNewPwdError(false)}, 7000)
          }
        }
      }
    }
  }

  const handleUpdateProfile = () => {
    const selectedTemperatureUnitVal = selectedTemperatureUnitRef?.current !== null ? selectedTemperatureUnitRef.current.value : null
    const selectedWindSpeedUnitVal = selectedWindSpeedUnitRef?.current !== null ? selectedWindSpeedUnitRef.current.value : null
    const selectedPrecipRateUnitVal = selectedPrecipRateUnitRef?.current !== null ? selectedPrecipRateUnitRef.current.value : null
    
    const putProfile = putCustomProfile({temperature: selectedTemperatureUnitVal, wind_speed: selectedWindSpeedUnitVal, precipitationRate:selectedPrecipRateUnitVal})
    dispatch(updateCustomUnits({temperature: selectedTemperatureUnitVal, wind_speed: selectedWindSpeedUnitVal, precipitationRate:selectedPrecipRateUnitVal}))
  }

  const editUser = () => {
    dispatch(
      updateContactDetails({
        userId: userId,
        firstname: userFirstName,
        lastname: userLastName,
        // email: userEmail,
        primary: userMobile,
      })
    )
  }

  return (
    <div className="max-h-screen flex flex-col w-full  overflow-y-hidden">
      <MainNavMenu />
      <div className="flex h-screen justify-center items-center mx-auto p-10 md:mt-[-12px]">
        <ul className="grid grid-cols-3 gap-2 md:gap-2 sm:mt sm:grid-cols-3 md:grid-cols-1  md:w-[800px]">
          <li>
            {' '}
            <div className="flex flex-col justify-start bg-gray-100 p-5 flex-1 rounded-2xl self-center">
              <div className="text-2xl font-light">
                <h2>User Settings</h2>
              </div>
              <div className="grid gap-4 grid-cols-2 mt-2">
                <div className="font-lato non-italic font-normal leading-4 text-sm ">
                  <p>
                    First Name<span className="text-red-500">*</span>
                  </p>
                  <input
                    className={`border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${
                      !editUserSettings
                        ? 'bg-white'
                        : 'dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400'
                    }`}
                    value={userFirstName}
                    disabled={!editUserSettings}
                    onChange={(e) => setUserFirstName(e.target.value)}
                  />
                </div>
                <div className="font-lato non-italic font-normal leading-4 text-sm">
                  <p>
                    Last Name<span className="text-red-500">*</span>
                  </p>
                  <input
                    className={`border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${
                      !editUserSettings
                        ? 'bg-white'
                        : 'dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400'
                    }`}
                    value={userLastName}
                    disabled={!editUserSettings}
                    onChange={(e) => setUserLastName(e.target.value)}
                  />
                </div>
                <div className="font-lato non-italic font-normal leading-4 text-sm">
                  <p>
                    E-mail<span className="text-red-500">*</span>
                  </p>
                  <input
                    className={`border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${
                      !editUserSettings
                        ? 'bg-white'
                        : 'dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400'
                    }`}
                    value={userEmail}
                    disabled={true}
                    onChange={(e) => setUserEmail(e.target.value)}
                  />
                </div>
                <div className=" font-lato non-italic font-normal leading-4 text-sm">
                  <p>
                    Mobile<span className="text-red-500">*</span>
                  </p>
                  <input
                    className={`border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${
                      !editUserSettings
                        ? 'bg-white'
                        : 'dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400'
                    }`}
                    value={userMobile}
                    disabled={!editUserSettings}
                    onChange={(e) => setUserMobile(e.target.value)}
                  />
                </div>
              </div>
              {editUserSettings ? (
                <div className="flex ">
                  <div className="py-2.5 mr-12 mt-4 text-center text-white bg-green-500 rounded-md w-24 h-10">
                    <button
                      onClick={() => {
                        editUser()
                      }}
                    >
                      SAVE
                    </button>
                  </div>
                  <div className="py-2.5 my-0 mt-4 text-center text-white bg-green-500 rounded-md w-24 h-10">
                    <button
                      onClick={() => {
                        setEditUserSettings(false)
                      }}
                    >
                      CANCEL
                    </button>
                  </div>
                </div>
              ) : (
                <div className="py-2.5 my-0 mt-4 text-center text-white bg-green-500 rounded-md w-24 h-10">
                  <button
                    onClick={() => {
                      setEditUserSettings(true)
                    }}
                  >
                    EDIT
                  </button>
                </div>
              )}
            </div>
          </li>
          <li>
            <div className="flex flex-col justify-start bg-gray-100 p-5 flex-1 rounded-2xl self-center" title="New password must contain minimum eight characters at least one uppercase letter, one lowercase letter, one special character and one number">
              <div className="text-2xl font-light">
                <h2>Change Password</h2>
              </div>
              <div className="grid gap-4 grid-cols-2 mt-2">
                <div className="font-lato non-italic font-normal leading-4 text-sm">
                  <p>
                    Old Password<span className="text-red-500">*</span>
                  </p>
                  <input
                    ref={oldPasswordRef}
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5
               dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    type="password"
                    name="oldpw"
                    style={oldPwdError? { border: '1px solid red' } : {}}
                  />
                </div>
                <div className="font-lato non-italic font-normal leading-4 text-sm">
                  <p>
                    Password<span className="text-red-500">*</span>
                  </p>
                  <input
                    ref={newPasswordRef}
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5
              dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    type="password"
                    name="newpw"
                    style={newPwdError? { border: '1px solid red' } : {}}
                  />
                </div>
                <div className="font-lato non-italic font-normal leading-4 text-sm">
                  <p>
                    Confirm Password<span className="text-red-500">*</span>
                  </p>
                  <input
                    ref={confirmNewPasswordRef}
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5
              dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    type="password"
                    name="confirmpw"
                    style={confirmNewPwdError? { border: '1px solid red' } : {}}
                  />
                </div>
                <div></div>
                <div className="py-2.5 my-0 text-center text-white bg-green-500 rounded-md w-40 h-10">
                  <button onClick={handleChangePassword}>SAVE CHANGES</button>
                </div>
              </div>
            </div>
          </li>
          <li>
            {' '}
            <div className="flex-1 bg-gray-100 p-5 rounded-2xl self-center mx-auto ">
              <div className="text-2xl font-light ">
                <h2>Custom Units</h2>
              </div>
              <ul className="grid gap-6 grid-cols-2 md:grid-cols-3 ">
                <li>
                  <div className="font-lato non-italic font-normal leading-4 text-sm mt-2 ">
                    <p>
                      Temperature<span className="text-red-500">*</span>
                    </p>
                    {isLoading ? (
                      <p>Loading...</p>
                    ) : (
                      <select
                        className="w-full border border-gray-300 text-gray-900 text-m rounded-lg block p-1.5 bg-white dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                        defaultValue={selectedTemperatureUnit}
                        onChange={(e) =>
                          setSelectedTemperatureUnit(e.target.value)
                        }
                        ref={selectedTemperatureUnitRef}
                      >
                        <option value="C">&#176; C</option>
                        <option value="F">&#176; F</option>
                      </select>
                    )}
                  </div>
                </li>
                <li>
                  <div className="font-lato non-italic font-normal leading-4 text-sm mt-2">
                    <p>
                      Wind Speed<span className="text-red-500">*</span>
                    </p>
                    {isLoading ? (
                      <p>Loading...</p>
                    ) : (
                      <select
                        className="w-full border border-gray-300 text-gray-900 text-m rounded-lg block p-1.5 bg-white dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                        defaultValue={selectedWindSpeedUnit}
                        onChange={(e) =>
                          setSelectedWindSpeedUnit(e.target.value)
                        }
                        ref={selectedWindSpeedUnitRef}
                      >
                        <option value="KTS">KTS</option>
                        <option value="MPH">MPH</option>
                        <option value="MPS">MPS</option>
                      </select>
                    )}
                  </div>
                </li>
                <li>
                  <div className="font-lato non-italic font-normal leading-4 text-sm mt-2">
                    <p>
                      Precipitation Rate<span className="text-red-500">*</span>
                    </p>
                    {isLoading ? (
                      <p>Loading...</p>
                    ) : (
                      <select
                        className="w-full border border-gray-300 text-gray-900 text-m rounded-lg block p-1.5 bg-white dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                        defaultValue={selectedPrecipRateUnit}
                        onChange={(e) =>
                          setSelectedPrecipRateUnit(e.target.value)
                        }
                      >
                        <option value="MM/HR">MM / HR</option>
                        <option value="IN/HR">IN / HR</option>
                      </select>
                    )}
                  </div>
                </li>
              </ul>
              <div className="col-span-2 py-2.5 mt-4 mb-26 text-center text-white bg-green-500 rounded-md w-40 h-10">
                <button
                  onClick={() => {
                    setLoading(true)
                    updateCustomProfiles(
                      selectedTemperatureUnit,
                      selectedWindSpeedUnit,
                      selectedPrecipRateUnit
                    ).then((res) =>
                      {
                        errorStatus.addMessage("Custom Units Updated Successfully",200,"success",false)
                        if (res) {
                          setLoading(false)
                          setToastMessage("Custom Units Updated Successfully")
                          setToastType(toastType.success)
                          setShowToast(true)
                          setTimeout(() => {
                            setShowToast(false)
                          }, 7000)            
                        }
                        setLoading(false)}
                    ) /** replaced (res) with ()  */
                    .catch((err) => {
                      errorStatus.addMessage("Error updating custom units",500,"error",false)
                    })
                  }}
                >
                  {loading ? 'SAVING...' : 'SAVE CHANGES'}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default UserProfile
