// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { SyntheticEvent, useEffect, useState } from 'react'
import { IMenuItem } from './SideNavItems'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectMenuItem,
  selectedItems,
  setSelectedItemIds,
  setSelectedItems,
  deselectMenuItem,
  expand,
  setStateChanged,
  clearSelectedItems,
  selectedItemIds,
  setActiveMenuItem,
} from './SideNavigationSlice'
import './SideNavigation.css'
import chevron from '../../assets/chevron.svg'
import chevronUp from '../../assets/chevron-up.svg'
import store, { RootState } from '../../store'
import {
  changBaseLayers,
  enableDarkMode,
  setActiveBaseLayer,
} from '../../map/mapSlice'
import { toggleMissionCast } from '../../Components/MissionCAST/MissionCASTSlice'
import useAlertSetting from '../../Components/Alerting/useAlertSetting'
import {
  callSpecificAlert,
  hideAlertSETTING,
  showAlertSETTING,
  showAlertSETTINGCreate,
} from '../../Components/Alerting/AlertingSlice/AlertingSlice'
import alert from '../../assets/alert.svg'
import useTWSMapContext from '../../hooks/UseTWSContext'
import { hideRadar } from '../../Components/Users/UserSlice'
import { elements } from '../../interfaces/alertingelements'
import { toggleRouteCast } from '../../Components/DecisionProducts/RouteCast/routecastSlice'
import { ProcessMapSelection } from '../../Components/MenuSelection/MenuSelection'
import { pushMessage } from '../../Components/Notifier/StatusQueueSlice'
import useErrorStatus from '../../hooks/UseErrorStatus'

/**
 * PLEASE READ
 * To add a menu item programatically, use the appendMenuItem action or the
 * prependMenuItem action.
 *
 * const item = {
 *   id: "an-id-that-will-be-used-for-this-action", // Required.
 *   label: "My Menu Item", // optional. ( for top-most menu item in heirarchy )
 *   link: 'https://www.google.com', //optional.
 *   icon: bigIcon, // optional.
 *   callback: verySpecialCallback({name: loc_name, lat: loc_lat, lng: loc_lng}), // optional. Use any extraData you need.
 *   multiSelect: false, // optional.
 *   subMenu: [], // required.
 * }
 * dispatch(appendMenuItem(item, parentMenuId))
 */

interface SideNavigationProps {
  setMessage: (name: string) => void
}

export default function SideNavigation(props: SideNavigationProps) {
  const sideMenuItems = useSelector(
    (state: RootState) => state.sideNav.menuItems
  )
  const dispatch = useDispatch()

  const classes = 'duration-200h-full bg-gray-900 thin-scrollbar'

  return (
    <div className={classes}>
      <MenuItem
        selectedSideNav={'empty'}
        setMessage={props.setMessage}
        level={0}
        subMenu={sideMenuItems}
        expanded={false}
      />
    </div>
  )
}

interface ISideNavigationProps {
  label?: string
  link?: string
  icon?: string
  multiSelect?: boolean
  subMenu: Array<IMenuItem>
  active?: boolean
  selectedChild?: (e: SyntheticEvent) => void // inform the parent that the child is selected
  level: number
  selected?: boolean
  disabled?: boolean
  expanded?: boolean // the menubar is maximized or minimized (icons only)
  children?: React.ReactNode
  setMessage: (label: string) => void // inform the parent that the child is selected
  selectedSideNav: string
  toggle?: boolean
}

export const IndividualAlerts = (prop) => {
  let col = '#'
  if (prop.prop == 'NWS Alerts') {
    col += 'FFA500'
  } else {
    col += elements.find((ele) => ele.id == prop.prop)?.color
  }
  return (
    <svg
      fill="none"
      width="22"
      height="20"
      viewBox="0 0 28 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={col}
        d="M15.25 15.5H12.75V9.25H15.25M15.25 20.5H12.75V18H15.25M0.25 24.25H27.75L14 0.5L0.25 24.25Z"
      />
    </svg>
  )
}

interface ISelectionIndicatorProps {
  enabled: boolean
}

export const SelectionIndicator = (props: ISelectionIndicatorProps) => {
  const enabled = props.enabled && props.enabled === true ? true : false
  const [check, setCheck] = useState<boolean>(enabled)
  // todo: for toggles with props.disabled == true set after:bg-gray-800 or similar to subdue the selection ( and set distabled to true in the input element )
  const changeHandler = () => {
    return
  }

  return (
    <>
      <input
        onChange={changeHandler}
        type="checkbox"
        value=""
        className="sr-only peer"
        checked={enabled}
      />
      {/* {enabled ? (
        <input type="checkbox" value="" className="sr-only peer" checked={check} />
      ) : (
        <input type="checkbox" value="" className="sr-only peer" />
      )} */}
      <div className="mt-1 relative w-7 h-4 bg-gray-800 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-gray-300 after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
      {/* larger toggle ->"relative w-9 h-5 bg-gray-800 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"  */}
    </>
  )
}

interface IRadioIndicatorProps {
  selected?: boolean
  itemId?: string
  checked?: boolean
}
export function RadioIndicator(props: IRadioIndicatorProps) {
  const exclusiveItems = [
    'observations-mrms-radar',
    'aviation-ceiling',
    'aviation-visibility',
    'aviation-temperature',
    'aviation-surface-winds',
    'aviation-winds-80m',
    'aviation-wind-gust',
    'aviation-surface-winds-ca',
    'aviation-winds-80m-ca',
    'observations-myradar',
    'aviation-surface-winds-uk',
    'aviation-winds-80m-uk',
    'aviation-temperature-uk',
    'aviation-visibility-uk',
    'aviation-ceiling-uk',
    'forecast-aviation-ceiling',
    'forecast-aviation-visibility',
    'forecast-aviation-temperature',
    'forecast-aviation-surface-winds',
    'forecast-aviation-winds-80m',
    'forecast-aviation-wind-gust',
  ]
  const toggledItems = [
    'aviation-pirep',
    'aviation-airmet',
    'aviation-sigmet',
    'aviation-metar',
    'aviation-taf',
    'camera-network',
    'vertical-profile',
  ]
  const itemID = props.itemId
  const selectedItems = store.getState().sideNav.selectedItemIds

  let checked
  for (let i = 0; i < exclusiveItems.length; i++) {
    const item = exclusiveItems[i]
  }

  if (
    exclusiveItems.includes(props.itemId as string) &&
    props.checked === true
  ) {
    return (
      <div className="mr-2">
        <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
          <circle cx="15" cy="15" r="8" fill="#00ff00" />
          <circle cx="15" cy="15" r="4" fill="black" />
        </svg>
      </div>
    )
  } else {
    return (
      <div className="mr-2">
        <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
          <circle cx="15" cy="15" r="6" fill="white" />
          <circle cx="15" cy="15" r="5" fill="black" />
        </svg>
      </div>
    )
  }
}

export const MenuItem = (props: ISideNavigationProps) => {
  const selectedIds = useSelector(
    (state: RootState) => state.sideNav.selectedItemIds
  )
  const [activeItem, setActiveItem] = useState<{ [key: number]: boolean }>({})
  const selectedItemsVar = useSelector(
    (state: RootState) => state.sideNav.selectedItems
  )
  const [previousSelectedMenuItem, setPreviousSelectedMenuItem] =
    useState<IMenuItem | null>(null)
  const menu = props.subMenu
  const dispatch = useDispatch()
  const errorStatus = useErrorStatus()
  const removeAlert = useSelector((state: RootState) => state.alert.removeAlert)
  const { addAlertSideNav } = useAlertSetting()
  const currentBaseLayer = useSelector(
    (state: RootState) => state.mapState.activeBaseLayer
  )
  const getDefaultLocation = useSelector(
    (state: RootState) => state.user.defaultLocation
  )
  const [expandedItems, setExpandedItems] = useState(() => {
    // Do not expand any items by default except the root
    const initialExpandedItems: { [key: number]: boolean } = {}
    menu.forEach((_, index) => {
      // Set the root element (index 0) to true, others to false
      initialExpandedItems[index] = props.level === 0 && index === 0
    })
    return initialExpandedItems
  })
  const expandedState = useSelector(
    (state: RootState) => state.sideNav.expanded
  )
  let selectedIndicator = <></>
  const [idToUnits, setIdToUnits] = useState({})
  const [keys, setKeys] = useState(Object.keys(idToUnits))
  const settingAlertNotification = useSelector(
    (state: RootState) => state.alert.setAlertingNotification
  )
  const [numberOfAlerts, setNumberOfAlerts] = useState(0)
  // const NumberOfAlert =
  useEffect(() => {
    const idtounits = idToUnits
    if (settingAlertNotification?.length > 0) {
      for (let i = 0; i < settingAlertNotification?.length; i++) {
        for (let j = 0; j < elements.length; j++) {
          if (
            settingAlertNotification[i]?.properties?.hazardType ===
            elements[j].value
          ) {
            if (!idToUnits[elements[j].id]) {
              idtounits[elements[j].id] = 0
            } else {
              idtounits[elements[j].id]++
            }
          }
        }
      }
      setIdToUnits(idtounits)
      const tempKeys = Object.keys(idToUnits).map((element) => {
        if (element.includes('NWS')) {
          return 'NWS Alerts'
        }
        return element
      })

      setKeys(tempKeys)
    }

    if (settingAlertNotification != '') {
      setNumberOfAlerts(settingAlertNotification.length - removeAlert.length)
    } else {
      setNumberOfAlerts(0)
    }
  }, [settingAlertNotification, idToUnits])

  const NumberOfAlerts = () => {
    let badge = <></>
    if (typeof numberOfAlerts !== 'undefined' && numberOfAlerts > 0) {
      badge = (
        <div className="absolute top-0 left-0 right-0 bg-red-600 w-4 h-4 text-white rounded-full text-xs p-0 text-center z-10">
          {numberOfAlerts}
        </div>
      )
    }
    if (numberOfAlerts > 0) {
      return (
        <button className="relative">
          {badge}
          <img src={alert} alt="" width="22" height="20" />
        </button>
      )
    } else {
      return (
        <div className="self-center">
          <img src={alert} alt="" width="22" height="20" />
        </div>
      )
    }
  }
  const toggledItems = [
    'aviation-pirep',
    'aviation-airmet',
    'aviation-sigmet',
    'aviation-metar',
    'aviation-taf',
    'camera-network',
    'vertical-profile',
  ]

  const exclusiveItems = [
    'observations-myradar',
    'observations-mrms-radar',
    'aviation-ceiling',
    'aviation-visibility',
    'aviation-temperature',
    'aviation-surface-winds',
    'aviation-winds-80m',
    'aviation-wind-gust',
    'aviation-surface-winds-ca',
    'aviation-winds-80m-ca',
    'aviation-surface-winds-uk',
    'aviation-winds-80m-uk',
    'aviation-temperature-uk',
    'aviation-visibility-uk',
    'aviation-ceiling-uk',
    'forecast-aviation-ceiling',
    'forecast-aviation-visibility',
    'forecast-aviation-temperature',
    'forecast-aviation-surface-winds',
    'forecast-aviation-winds-80m',
    'forecast-aviation-wind-gust',
  ]

  const exclusiveItemLabels = [
    'MyRadar',
    'Current Radar',
    'Cloud Ceiling',
    'Visibility',
    'Temperature',
    'Surface Winds',
    '80m Winds',
    'Wind Gust',
    'Surface Winds (CA)',
    '80m Winds (CA)',
    'Surface Winds (UK)',
    '80m Winds (UK)',
    'Temperature (UK)',
    'Visibility (UK)',
    'Ceiling (UK)',
  ]

  const isIdSelected = (id: string) => {
    const selectedItems = store.getState().sideNav.selectedItemIds
    if (selectedItems.includes(id)) {
      return true
    } else {
      return false
    }
  }

  const withToggleIndicator = (item: IMenuItem) => {
    const itemsArray = [
      'PIREP',
      'AIRMET',
      'SIGMET',
      'METAR',
      'TAF',
      'Camera Network',
    ]
    const selectedItemsIdsArray = store.getState().sideNav.selectedItemIds
    if (
      toggledItems.includes(item.id) &&
      selectedItemsIdsArray.includes(item.id)
    ) {
      return true
    } else {
      return false
    }
  }

  // const darkMode = useSelector((state: RootState) => {state.mapState.darkMode})
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)

  const selectedItemsArray = useSelector(
    (state: RootState) => state.sideNav.selectedItems
  )

  const { removeFromTWS } = useTWSMapContext()
  const childClicked = (e: SyntheticEvent, label: string) => {
    e.preventDefault()
  }

  const activeBaseLayer = useSelector(
    (state: RootState) => state.mapState.activeBaseLayer
  )

  const isItemSelected = (item: IMenuItem) => {
    const items = store.getState().sideNav.selectedItems
    // if (items.includes(item.id))
  }
  const eventCount = useSelector((state) => state.alert.eventCount)

  const handleClick = (e: SyntheticEvent, index: number, id: string) => {
    if (selectedItemsArray.includes('Location')) {
      const temp = selectedItemsArray
      dispatch(clearSelectedItems())
      const products = [
        'Surface Observations',
        'TAF',
        'METAR',
        'AIRMET',
        'SIGMET',
        'PIREP',
        'Camera Network',
        'Vertical Profile',
        'MyRadar',
        'MissionCast',
        'RouteCast',
      ]
      for (const i in temp) {
        if (products.includes(temp[i])) {
          dispatch(selectedItems(temp[i]))
        }
      }
      if (temp.includes('Alerts')) {
        dispatch(selectedItems('Alerts'))
        elements.forEach((element) => {
          if (temp.includes(element.id)) {
            dispatch(selectedItems(element.id))
          }
        })
      }
      if (temp.includes('Alertss')) {
        dispatch(selectedItems('Alertss'))
      }
      if (temp.includes('Events List')) {
        dispatch(selectedItems('Events List'))
      } else {
        dispatch(selectedItems('Location'))
      }
    }
    if (selectedItemsArray.includes('location-menu')) {
      dispatch(selectedItems(getDefaultLocation))
    }
    e.preventDefault()
    const clickedItem = menu[index]
    const isToggleEnabled = clickedItem.toggle === true

    if (expandedState === false) {
      dispatch(expand())
      return null
    }

    if (props.selectedChild) {
      props.selectedChild(e)
    }

    // Determine whether multiSelect is enabled for this item
    const isMultiSelectEnabled = menu[index].multiSelect === true

    // If multiSelect is not enabled, clear all active items except the clicked one
    const newActiveItem: { [key: number]: boolean } = isMultiSelectEnabled
      ? { ...activeItem }
      : {}

    if (!store.getState().sideNav.selectedItemIds.includes(menu[index].id)) {
      // if item is not selected add it to the selectedItems arrays

      let newSelection = [...store.getState().sideNav.selectedItemIds]
      if (exclusiveItems.includes(menu[index].id)) {
        for (let i = 0; i < exclusiveItems.length; i++) {
          const item = exclusiveItems[i]
          if (newSelection.includes(item)) {
            newSelection.splice(newSelection.indexOf(item), 1)
          }
        }
      }
      newSelection.push(menu[index].id)

      dispatch(setSelectedItemIds(newSelection))

      newSelection = [...store.getState().sideNav.selectedItems]
      if (exclusiveItemLabels.includes(menu[index].label as string)) {
        for (let i = 0; i < exclusiveItemLabels.length; i++) {
          const item = exclusiveItemLabels[i]
          if (newSelection.includes(item)) {
            newSelection.splice(newSelection.indexOf(item), 1)
          }
        }
      }
      newSelection.push(menu[index].label as string)

      dispatch(setSelectedItems(newSelection))

      newSelection = [...store.getState().sideNav.selectedItemIds]
      if (clickedItem.id.includes('locations-')) {
        const idx = newSelection.findIndex((item) => {
          if (item.includes('locations-')) {
            return true
          }
        })
        newSelection.splice(idx, 1)
      }
      dispatch(setSelectedItemIds(newSelection))
    } else {
      // item is already selected, remove it from the selectedItems arrays
      if (menu[index].id === 'camera-network') {
        // putting this here is a bit of a hack, but it works for now.
        // will want to move the logic to MenuSelection on future iteration.
        removeFromTWS('camera-network')
      } else if (menu[index].id === 'vertical-profile') {
        removeFromTWS('vertical-profile')
      }
      if (menu[index].id === 'decision-missioncast') {
        dispatch(toggleMissionCast(false))
      }
      let newSelection = store
        .getState()
        .sideNav.selectedItemIds.filter((id) => id !== menu[index].id)
      dispatch(setSelectedItemIds(newSelection))
      newSelection = store
        .getState()
        .sideNav.selectedItems.filter((item) => item !== menu[index].label)
      dispatch(setSelectedItems(newSelection))
    }

    if (menu[index].id.startsWith('maps-')) {
      dispatch(setStateChanged({ itemId: menu[index].id })) //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
      dispatch(changBaseLayers(menu[index].id)) //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
      // ProcessMapSelection(clickedItem.id)
    }

    newActiveItem[index] = !activeItem[index]

    if (
      clickedItem.label === 'RouteCast' &&
      !selectedItemsArray.includes('RouteCast')
    ) {
      const newSelection = [...store.getState().sideNav.selectedItems]
      if (newSelection.includes('Create an Alert')) {
        newSelection.splice(newSelection.indexOf('Create an Alert'), 1)
        dispatch(setSelectedItems(newSelection))
        dispatch(hideAlertSETTING())
        dispatch(showAlertSETTINGCreate(false))
      }
      const newSelectionId = [...store.getState().sideNav.selectedItemIds]
      if (newSelectionId.includes('alerts-create')) {
        errorStatus.addMessage(
          "RouteCast and Alerting can't be active at the same time. Disabling Alerting now.",
          200,
          'notification',
          false
        )
        newSelectionId.splice(newSelectionId.indexOf('alerts-create'), 1)
        dispatch(setSelectedItemIds(newSelectionId))
        dispatch(hideAlertSETTING())
        dispatch(showAlertSETTINGCreate(false))
      }
      dispatch(toggleRouteCast(true))
    }
    if (
      clickedItem.label === 'Create an Alert' &&
      !selectedItemsArray.includes('Create an Alert')
    ) {
      const newSelection = [...store.getState().sideNav.selectedItems]
      if (newSelection.includes('RouteCast')) {
        newSelection.splice(newSelection.indexOf('RouteCast'), 1)
        dispatch(setSelectedItems(newSelection))
        dispatch(toggleRouteCast(false))
      }
      const newSelectionId = [...store.getState().sideNav.selectedItemIds]
      if (newSelectionId.includes('decision-routcase')) {
        errorStatus.addMessage(
          "RouteCast and Alerting can't be active at the same time. Disabling RouteCast now.",
          200,
          'notification',
          false
        )
        newSelectionId.splice(newSelectionId.indexOf('decision-routcase'), 1)
        dispatch(setSelectedItemIds(newSelectionId))
        dispatch(toggleRouteCast(false))
      }
      if (eventCount < 3) {
        dispatch(showAlertSETTING())
        dispatch(showAlertSETTINGCreate(true))
      }
    }
    setActiveItem(newActiveItem)
    const clickedItemLabel = clickedItem.label // Get the label of the clicked item

    if (props.label === 'Alerts') {
      addAlertSideNav(clickedItemLabel)
      if (clickedItemLabel != 'Events List') dispatch(callSpecificAlert(true))
    }
    // Check if the clicked item is a leaf node (no subMenu or empty subMenu)
    props.setMessage(
      typeof clickedItemLabel !== 'undefined' && clickedItemLabel !== null
        ? clickedItemLabel
        : ''
    )

    if (previousSelectedMenuItem && previousSelectedMenuItem === clickedItem) {
      if (
        store
          .getState()
          .sideNav.selectedItemIds.includes('decision-missioncast')
      ) {
        dispatch(toggleMissionCast(false))
      } else if (
        previousSelectedMenuItem.id === 'alerts-create' &&
        selectedItemsArray.includes('Create an Alert')
      ) {
        dispatch(hideAlertSETTING())
        dispatch(showAlertSETTINGCreate(false))
      } else if (
        previousSelectedMenuItem.id === 'decision-routcase' &&
        selectedItemsArray.includes('RouteCast')
      ) {
        dispatch(toggleRouteCast(false))
      }

      if (previousSelectedMenuItem.id === 'observations-myradar') {
        dispatch(hideRadar())
      }
      if (previousSelectedMenuItem.id === 'location-menu') {
        dispatch(selectedItems(getDefaultLocation.name))
      }
      dispatch(deselectMenuItem(previousSelectedMenuItem))
      setPreviousSelectedMenuItem(null) // Reset the previous selection
    } else {
      dispatch(selectMenuItem(clickedItem))
      setPreviousSelectedMenuItem(clickedItem) // Set the new previous selection
      if (
        clickedItem.label === 'RouteCast' &&
        selectedItemsArray.includes('RouteCast')
      ) {
        dispatch(toggleRouteCast(false))
      }

      if (
        clickedItem.label === 'Create an Alert' &&
        selectedItemsArray.includes('Create an Alert')
      ) {
        dispatch(hideAlertSETTING())
        dispatch(deselectMenuItem(previousSelectedMenuItem))
      }
      if (clickedItem.id === 'aviation-metar') {
        if (clickedItem !== null && typeof clickedItem !== 'undefined') {
          selectedIndicator = <SelectionIndicator enabled={true} />
        } else {
          selectedIndicator = <></>
        }
      }
    }

    if (
      typeof clickedItemLabel !== 'undefined' &&
      clickedItemLabel !== null &&
      (clickedItem.subMenu === undefined ||
        // the following are exceptions when we want to process selections
        // that are not leaf nodes of the menu heirarchy.
        clickedItemLabel === 'Surface Observations' || // <-- allows the SlideOverDialog
        clickedItemLabel === 'Alerts')
    ) {
      props.setMessage(
        typeof clickedItemLabel !== 'undefined' && clickedItemLabel !== null
          ? clickedItemLabel
          : ''
      )
    }

    setExpandedItems({
      ...expandedItems,
      [index]: !expandedItems[index],
    })

    if (clickedItem.id === 'dark-mode-menu') {
      dispatch(
        changBaseLayers('maps-road', store.getState().mapState.activeBaseLayer)
      )
      dispatch(enableDarkMode(!darkMode))

      dispatch(setActiveMenuItem({ id: 'maps-satellite', active: false }))
      dispatch(setActiveMenuItem({ id: 'maps-sectional', active: false }))
    }

    if (
      (clickedItem.id === 'maps-satellite' ||
        clickedItem.id === 'maps-sectional') &&
      darkMode
    ) {
      dispatch(enableDarkMode(false))
    }

    if (isToggleEnabled) {
      if (
        previousSelectedMenuItem &&
        previousSelectedMenuItem === clickedItem
      ) {
        // Handle deselection
        dispatch(deselectMenuItem(previousSelectedMenuItem))
        setPreviousSelectedMenuItem(null) // Reset the previous selection
      } else {
        // Handle selection
        dispatch(selectMenuItem(clickedItem))
        setPreviousSelectedMenuItem(clickedItem) // Set the new previous selection
      }
    }
  }

  return (
    <div
      className="bg-black text-white text-left flex flex-col h-full overflow-y-auto overflow-x-clip"
      style={expandedState ? { width: '270px' } : {}}
    >
      {menu.map((item, index) => {
        let itemClass: string
        if (
          selectedItemsVar.includes('RouteCast') &&
          item.label === 'RouteCast'
        ) {
          itemClass = 'inactive-item'
        }

        if (selectedIds.includes(item.id)) {
          itemClass = 'active-item'
        } else if (props.selected) {
          itemClass = 'selected-item'
        } else {
          itemClass = 'inactive-item'
        }

        if (
          item.id === 'maps-satellite' ||
          (item.id === 'maps-sectional' && darkMode)
        ) {
          itemClass = 'inactive-item'
        }
        if (item.id === 'maps-road' && darkMode) {
          itemClass = 'active-item'
        }
        if (item.id.includes('maps-')) {
          if (currentBaseLayer !== item.id && item.id !== 'maps-menu') {
            itemClass = 'inactive-item'
          } else if (currentBaseLayer === item.id) {
            itemClass = 'active-item'
          }
        }

        // direction of chevron
        let chev = ''
        if (
          store
            .getState()
            .sideNav.selectedItemIds.includes(item.label ? item.label : '') &&
          item.label &&
          item.subMenu &&
          item.subMenu.length > 0
        ) {
          chev = chevronUp
        } else {
          chev = chevron
        }

        // if disabled
        let showItem = ''
        if (item.label === '') {
          showItem = 'hidden'
        } else {
          if (props.disabled) {
            showItem = 'text-gray-600 flex flex-row py-2 pr-4 hover-item'
          } else {
            showItem = 'flex flex-row py-2 pr-4 hover-item'
          }
        }

        const showClass =
          !expandedState && props.level > 1
            ? 'hidden'
            : item.disabled || props.disabled
            ? '!cursor-not-allowed text-gray-600' //pointer-events-none
            : 'cursor-pointer'
        const disabledClass = props.disabled
          ? 'text-gray-500 cursor-not-allowed'
          : ''
        const pointerStyle =
          item.disabled || props.disabled
            ? { cursor: 'not-allowed' }
            : { cursor: 'pointer' }
        return (
          <div key={index} className={`${showClass} `}>
            <div className={`${itemClass} ${disabledClass}`}>
              {}
              <div
                className={showItem}
                style={{
                  paddingLeft: `${5 * props.level * 3}px`,
                  ...pointerStyle,
                }}
                onClick={(e) => {
                  handleClick(e, index, item.id)
                  if (item.callback !== undefined) {
                    item.callback()
                  }
                }}
              >
                {expandedState && item.icon && (
                  <img
                    src={item.icon}
                    className="h-5 w-5 mt-1"
                    alt=""
                    title={
                      item.label !== undefined && item.label !== null
                        ? item.label
                        : ''
                    }
                  />
                )}
                {!expandedState && props.level === 1 && item.icon ? (
                  <img
                    src={item.icon}
                    className="h-5 w-5 mt-1"
                    alt=""
                    title={
                      item.label !== undefined && item.label !== null
                        ? item.label
                        : ''
                    }
                  />
                ) : null}
                {item.label === 'Alerts' && !item.icon && <NumberOfAlerts />}

                {props.label === 'Alerts' &&
                  props.level === 2 &&
                  !keys.includes(item.label as string) && (
                    <>
                      <div className="mr-5"></div>
                    </>
                  )}
                {props.label === 'Alerts' &&
                  props.level === 2 &&
                  keys.includes(item.label as string) && (
                    <IndividualAlerts prop={item.label} />
                  )}

                {/* {!item.icon && <div className="h-6 w-6"></div>} */}
                {expandedState ? (
                  <>
                    <div className="flex flex-grow shrink basis-auto pl-3 text-lg">
                      {toggledItems.includes(item.id) ? (
                        withToggleIndicator(item) ? (
                          <div className="mr-3">
                            <SelectionIndicator enabled={true} />
                          </div>
                        ) : (
                          <div className="mr-3">
                            <SelectionIndicator enabled={false} />
                          </div>
                        )
                      ) : null}
                      {item.id === 'dark-mode-menu' ? (
                        <div className="mr-3">
                          <SelectionIndicator
                            enabled={
                              typeof darkMode === 'boolean' ? darkMode : false
                            }
                          />
                          {/* enabled={!darkMode} */}
                        </div>
                      ) : null}
                      {item.specialComponent !== undefined
                        ? item.specialComponent
                        : null}
                      {exclusiveItems.includes(item.id) ? (
                        <RadioIndicator
                          checked={isIdSelected(item.id) ? true : false}
                          itemId={item.id}
                        />
                      ) : null}
                      <span className="mr-3">{item.label}</span>
                    </div>
                    <div className="grow-0 shrink-0 basis-4">
                      {item.label &&
                        item.subMenu &&
                        item.subMenu.length > 0 && (
                          <img src={chev} alt="chevron" className="mt-2" />
                        )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              {expandedItems[index] && (
                <MenuItem
                  disabled={
                    item.disabled && item.disabled === true ? true : false
                  }
                  selectedSideNav={props.selectedSideNav}
                  setMessage={props.setMessage}
                  multiSelect={
                    item.multiSelect && item.multiSelect !== undefined
                      ? item.multiSelect
                      : false
                  }
                  subMenu={
                    typeof item.subMenu !== 'undefined' ? item.subMenu : []
                  }
                  level={props.level + 1}
                  selectedChild={(e) =>
                    childClicked(
                      e,
                      item.label !== undefined && item.label !== null
                        ? item.label
                        : ''
                    )
                  }
                  label={
                    item.label !== undefined && item.label !== null
                      ? item.label
                      : ''
                  }
                />
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}
