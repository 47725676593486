import { getSensorData } from '../api/SensorDetailsAPIService';
import { TWS_API_URL } from '../environment/apis.config';
import { SurfaceObsAPIParser } from '../utils/SurfaceObsUtils';
import { timeouts } from './RequestTimeouts';

const API_ENDPOINT = TWS_API_URL + '/weatherobs';
const radius = 100;

const SurfaceObsAPI = async (defaultLocation) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
  }, timeouts.surfaceObs);

  try {
    const requestBody = await getSensorData();
    const intelToken = [];
    const tempestToken = [];
    requestBody.forEach((element) => {
      if (element.sensorType === 'Intellisense') {
        intelToken.push(element.identity);
      } else if (element.sensorType === 'Tempest') {
        tempestToken.push(element.identity);
      }
    });

    const response = await fetch(`${API_ENDPOINT}/${defaultLocation.latitude}/${defaultLocation.longitude}?radius=${radius}`, {
      method: 'POST',
      headers: {
        // Authorization: `Bearer ` + sessionStorage.getItem('token'),
        Authorization: `Bearer ` + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ inteldevice: intelToken, tempestToken: tempestToken }),
      signal: controller.signal,
    });

    clearTimeout(timeoutId);

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.status}`)
    }

    const data = await response.json()
    return SurfaceObsAPIParser(data)
  } catch (error) {
    clearTimeout(timeoutId)
    // Rethrow error 
    throw new Error(`SurfaceObsAPI request timed out. Please try again.`)
  }

};

export function SurfaceObsAPIIconVisibility(
  feature: any,
  setProductVisibility: string[]
): boolean {
  const sensorTypeValue = feature?.properties?.SensorType?.value
  if (sensorTypeValue && setProductVisibility.includes(sensorTypeValue)) {
    return true
  }
  return false
}

export default SurfaceObsAPI
