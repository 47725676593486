//npm module imports
import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk'
import sideNavReducer from './menus/SideNavigation/SideNavigationSlice'
import tafReducer from './services/TAF/tafslice'
import asyncDispatchMiddleware from './Middlewares/asyncDispatchMiddleware'

//component imports
import metarReducer from './services/METAR/METARSlice'
import userReducer from './Components/Users/UserSlice'
import MissionCASTReducer from './Components/MissionCAST/MissionCASTSlice'
import { useDispatch } from 'react-redux'
import routecastReducer from './Components/DecisionProducts/RouteCast/routecastSlice'
import pirepReducer from './services/PIREP/PIREPSlice'
import sigmetReducer from './services/SIGMET/SIGMETSlice'
import airmetReducer from './services/AIRMET/AIRMETSlice'
import AlertReducer from './Components/Alerting/AlertingSlice/AlertingSlice'
import mapSlice from './map/mapSlice'
import WetDogWeatherProductsReducer from './Components/WetdogWeatherProducts/WetDogWeatherProductsSlice'
import StatusQueueSlice from './Components/Notifier/StatusQueueSlice'

const combinedReducer = combineReducers({
  user: userReducer,
  sideNav: sideNavReducer,
  metar: metarReducer,
  rcast: routecastReducer,
  taf: tafReducer,
  airmet: airmetReducer,
  pirep: pirepReducer,
  sigmet: sigmetReducer,
  missioncast: MissionCASTReducer,
  alert: AlertReducer,
  mapState: mapSlice,
  messages: StatusQueueSlice,
  WetDogWeatherProducts: WetDogWeatherProductsReducer,
})

export type RootState = ReturnType<typeof combinedReducer>

const store = configureStore({
  reducer: combinedReducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    thunkMiddleware,
    asyncDispatchMiddleware,
  ],
})

export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()

export const resetReduxStore = () => {
  store.dispatch({ type: 'RESET_STORE' })
}

export default store
