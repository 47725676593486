import marker from '../../../assets/marker1.svg'
import clouds from '../../../assets/clouds.svg'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'

export default function LocationIndicator() {
  const locationName = useSelector(
    (state: RootState) => state.user.defaultLocation?.name
  )
  const [showLocation, setShowLocation] = useState(true)
  const [showDate, setShowDate] = useState(true)
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const date = new Date()
  const dateString = `${date.toLocaleDateString().split('/')[1]} ${
    month[date.getMonth()]
  } ${date.toLocaleDateString().split('/')[2]}`

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth

      if (windowWidth <= 1000) {
        setShowDate(false)
      } else {
        setShowDate(true)
      }
    }
    // Add the event listener when the component mounts
    window.addEventListener('resize', handleResize)

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  },[])

  return (
    <div className={showDate ? "relative box-border w-96 self-center basis-11 grow-0 shrink-0":"relative box-border w-64 self-center basis-11 grow-0 shrink-0"}>
      <div
        className={showDate ? "flex flex-row w-96 h-11 pl-0.5 bg-gray-100 justify-between" : "flex flex-row w-64 h-11 pl-0.5 bg-gray-100 justify-between"}
        style={{ borderRadius: '25px' }}
      >
        <img
          className="relative mx-4 marker-img top-2 h-6"
          src={marker}
          alt=""
        />
        {locationName && (
          <div
            className={`m-3 text-ellipsis whitespace-nowrap`}
            style={{
              maxWidth: '60%',
            }}
          >
            {locationName.length > 10
              ? `${locationName.slice(0, 10)}...`
              : locationName}
          </div>
        )}
        {showDate && <div className="m-3">{dateString}</div>}
        <img className="h-6 m-2" src={clouds} alt="" />
      </div>
    </div>
  )
}
