import React, { useEffect, useState } from 'react';
import { getSensorData, getTempestToken, updateSensorData } from '../../api/SensorDetailsAPIService';
// import ReactSwitch from 'react-switch';
import Trash from '../../assets/trash.svg'
import Pen from '../../assets/icons/edit.svg'
import Cross from '../../assets/cross-icon.svg'
import Toast, { toastPosition, toastType } from '../Toast/Toast';
import MainNavMenu from '../../menus/MainNavMenu/MainNavMenu';
function SensorDetails() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [inputRows, setInputRows] = useState([
    {
      sensorType: 'Intellisense',
      identity: '',
      isPublic: false,
      edit: false,
    },
  ]);
  const [pendingChanges, setPendingChanges] = useState(false);
  const [isSaveSuccess, setIsSaveSuccess] = useState(false); // State variable for success toast
  const [toastMessage, setToastMessage] = useState(''); // State variable for toast message

  const addInputRow = () => {
    setPendingChanges(true)
    setInputRows([...inputRows, { sensorType: 'Intellisense', identity: '', isPublic: false, edit:false}]);
  };

  const deleteInputRow = (index) => {
    const updatedRows = [...inputRows];
    updatedRows.splice(index, 1);
    setPendingChanges(true)
    setInputRows(updatedRows);
  };

  useEffect(() => {
    const promise = getSensorData()
    promise.then((res) => {
        setInputRows(res);
      });
  }, []); 
      
  const handleSensorTypeChange = (index, value) => {
    const updatedRows = [...inputRows];
    updatedRows[index].sensorType = value;
    setInputRows(updatedRows);
    setPendingChanges(true); 
  };

  const handleidentityChange = (index, value) => {
    const updatedRows = [...inputRows];
    updatedRows[index].identity = value;
    setInputRows(updatedRows);
    setPendingChanges(true); 
  };

  const handleisPublicChange = (index, value) => {
    const updatedRows = [...inputRows];
    updatedRows[index].isPublic = value;
    setInputRows(updatedRows);
    setPendingChanges(true); 
  };
  const handleEditChange = (index, value) => {
    const updatedRows = [...inputRows];
    updatedRows[index].edit = value;
    setInputRows(updatedRows);
    setPendingChanges(true); 
  };

  const cancelEdit = (index) => {
    const promise = getSensorData()
    promise.then((res) => {
    const updatedRows = [...inputRows];
    if(index < res.length) updatedRows[index] = res[index]
    else updatedRows[index] = { sensorType: 'Intellisense', identity: '', isPublic: false, edit:false}
    setInputRows(updatedRows);
    })
  }
  const saveChanges = () => {
    updateSensorData(inputRows)
      .then(() => {
        setPendingChanges(false); 
        setToastMessage("Success");
        setIsSaveSuccess(true);
        setTimeout(() => {
          setIsSaveSuccess(false);
        }, 3000);
        // return "Saved Successfuly"
      })
      .catch((error) => {
        console.error('Error saving changes:', error);
        return error
      });
  };
  
  return (
    <div className="max-h-screen flex flex-col w-full  overflow-y-hidden">{/*flex flex-col w-full bg-white items-center*/}
      <MainNavMenu/>
      <div className="flex h-screen justify-center items-center mx-auto p-10 md:mt-[-12px] text-base">{/*flex flex-row mx-8 basis-full justify-center style={{ maxWidth: '1280px' }} w-1/2*/}
        <div className="flex flex-col justify-start bg-gray-100 p-5 flex-1 rounded-2xl self-center">
          <div className="text-2xl font-light p-2">
            <h2>Weather Sensor Details</h2>
          </div>
          <div className="grid gap-3">
            <p className='text-xs'>
              If you are authorized to access sensors, please press the “Edit” button to enter the Device ID(s)/Token(s). This will automatically add those sensors to your observation map.
            </p>
            <div className="table-container">
            <table className="table">
              <thead>
                <tr>
                  <th className="px-8 py-2 text-gray-900 w-48 text-left text-sm font-semibold">Sensor Type</th>
                  <th className="px-4 py-2 text-gray-900 text-left text-sm">ID / Token</th>
                  <th className="px-4 py-2 text-gray-900 text-left text-sm">Data Shared</th>
                  <th className="px-4 py-2 text-gray-900 text-left text-sm">Edit</th>
                  <th className="px-4 py-2 text-gray-900 text-left text-sm">Delete</th>
                </tr>
              </thead>
              <tbody>
                {/* Add rows with sensor details here */}
                {inputRows.map((row, index) => (
                  <tr>
                    <td className="px-8 py-2">
                      {!row.edit ? (
                        row.sensorType
                      ) : (
                        <select
                          className="block rounded-lg appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-2 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                          value={row.sensorType}
                          onChange={(e) => handleSensorTypeChange(index, e.target.value)}
                        >
                          <option value="Intellisense">Intellisense</option>
                          <option value="Tempest">Tempest</option>
                          {/* Add more options as needed */}
                        </select>
                      )}
                    </td>
                    <td className="px-4 py-2">
                      {!row.edit ? (
                        row.identity
                      ) : (
                        <input
                          className="block rounded-lg appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                          type="text"
                          value={row.identity}
                          onChange={(e) => handleidentityChange(index, e.target.value)}
                          placeholder="Enter Sensor ID / Token"
                        />
                      )}
                    </td>
                    <td className="px-4 py-2">
                      {!row.edit ? (
                        row.isPublic ? "true" : "false"
                      ) : (
                        row.sensorType === "Tempest" && (
                          <div className="relative inline-block w-10 align-middle select-none transition duration-200 ease-in">
                            <label className="relative inline-flex items-center cursor-pointer">
                              <input
                                type="checkbox"
                                value=""
                                className="sr-only peer"
                                checked={row.isPublic}
                                onChange={() => handleisPublicChange(index, !row.isPublic)}
                              />
                              <div className="w-11 h-6 bg-gray-400 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            </label>
                          </div>
                        )
                      )}
                    </td>
                    <td className="px-4 py-2">
                      
                    {!row.edit ? (<button
                        className="block appearance-none w-full bg-gray-100 px-4 py-2 leading-tight focus:outline-outline focus:shadow-outline hover:bg-white"
                        onClick={() => handleEditChange(index, !row.edit)}
                        title='Edit Token'
                      >
                        
                          <img className="w-6 h-6" src={Pen} alt="" />
                          </button>
                        ) : (
                          <button
                            className="block appearance-none w-full bg-gray-100 px-4 py-2 leading-tight focus:outline-outline focus:shadow-outline hover:bg-white"
                            onClick={() => cancelEdit(index)}>
                          <img className="w-6 h-6" src={Cross} alt="" />
                          </button>)}
                    </td>
                    <td className="px-4 py-2">
                      <button
                        className="block appearance-none w-full bg-gray-100 px-4 py-2 leading-tight focus:outline-outline focus:shadow-outline border-gray-300 hover:bg-gray-200"
                        onClick={() => deleteInputRow(index)}
                        title='Delete Token'
                      >
                        <img className="w-6 h-6" src={Trash} alt="" />
                      </button>
                      
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>


            </div>
            <div className="button-container flex flex-row"> {/*bg-blue-500*/}
              <button className="button mr-4 text-center text-gray-900 border-gray-300 border rounded-md w-48 h-10 hover:bg-gray-200"
              onClick={getTempestToken}>
                Get Tempest token...
              </button>
              <button
                className="button mr-4 text-center text-white bg-green-500 rounded-md w-40 h-10"
                onClick={addInputRow}>
                Add New Sensor
              </button>
            </div>
            <div className="button-container flex justify-end">
              { pendingChanges ? (<button
                className="button mr-4 text-center text-white bg-green-500 rounded-md w-40 h-10 font-medium"
                onClick={()=>saveChanges()}  
              >
                SAVE
              </button>) :
              (<button
                className="button mr-4 text-center text-white bg-green-500 rounded-md w-40 h-10 font-medium"
                disabled
              >
                SAVE
              </button>) 
              }

              {isSaveSuccess && (
                      <Toast
                        toastPosition={toastPosition.topRight}
                        toastMessage={toastMessage}
                        toastType={toastType.success}
                      />
                    )}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SensorDetails;
