import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAIRMETData } from "./AIRMETAPI";

import { AirmetParsedData } from "../../interfaces/airmet";

  const initialState = {
    data: [] as AirmetParsedData[],
    status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
    error: null,
  };
  export const getAirmetData = createAsyncThunk(
    'location/addNewLocation',
    async () => {
      const response = await fetchAIRMETData()
      return response
    }
  )
  
  const airmetSlice = createSlice({
    name: 'airmet',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getAirmetData.pending, (state) => {
          state.status = 'loading';
          state.error = null;
        })
        .addCase(getAirmetData.fulfilled, (state, action) => {
          state.status = 'succeeded';
        })
        .addCase(getAirmetData.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });
    },
  });
  
  export default airmetSlice.reducer;


