import SurfaceObsWeatherData from '../interfaces/surfaceObs'

interface SurfaceObsParsedData {
  latitude: number
  longitude: number
  properties?: { [key: string]: any }
}

export function SurfaceObsAPIParser(apiresponse: SurfaceObsWeatherData) {
  const parsedArray: SurfaceObsParsedData[] = []
  apiresponse?.features?.forEach((feature) => {
    const newObject: SurfaceObsParsedData = {
      latitude: feature.geometry.coordinates[1],
      longitude: feature.geometry.coordinates[0],
      properties: feature.properties,
    }
    parsedArray.push(newObject)
  })
  return parsedArray
}

// Function to extract unique SensorType values
export function getUniqueSensorTypes(data: any): string[] {
  const uniqueSensorTypes = new Set<string>()
  console.log("getUniqueSensorTypes",data)
  data?.forEach((feature: any) => {
    const sensorType = feature?.properties?.SensorType?.value
    uniqueSensorTypes.add(sensorType)
  })
  return Array.from(new Set(uniqueSensorTypes))
}

export const SurfaceObsSensors = []
