const MapPopupTable = ({ properties, units = {} }) => {
  const capitalizeStr = (str) => {
    const caps = str.charAt(0).toUpperCase() + str.slice(1)
    return caps
  }
  let keys: string[] = []
  const tableData = Object.entries(properties).map(([key, value], index) => {
    keys.push(key)
    if (Object.keys(properties).includes('Raw TAF') && key != 'Unit') {
      if (Object.keys(units).includes(key)) {
        return {
          key,
          value,
          units: units[key],
        }
      }
      return {
        key,
        value,
      }
    } else if (
      properties?.['Report Type'] &&
      properties?.['Report Type'].value == 'METAR'
    ) {
      return { key, value: value.value, units: value.unit }
    } else if (
      (properties?.data && properties.data == 'AIRMET') ||
      properties.data == 'SIGMET' ||
      properties.data == 'AIREP'
    ) {
      if (key == 'altitudeLow1') {
        return { key, value: value.toString() }
      } else {
        return { key, value: value }
      }
    } else if (properties.Data?.StationID || properties) {
      if (!key.startsWith('CloudLayer')) {
        return { key, value: value.value, units: value.units }
      } else {
        let heightAGL = null
        if (value.value.HeightAGL === null) {
          if (value.value.SkyCondition === 'clear') {
            //value.value.SkyCondition
            heightAGL = capitalizeStr(value.value.SkyCondition) //value.value.HeightAGL
          } else {
            value.value.SkyCondition.charAt(0).toUpperCase() +
              value.value.SkyCondiction.slice(1)
            heightAGL =
              capitalizeStr(value.value.SkyCondition) + ' at ' + '20000 FT' //value.value.HeightAGL
          }
        } else {
          if (value.value.SkyCondition === 'clear') {
            heightAGL = capitalizeStr(value.value.SkyCondition) //value.value.HeightAGL
          } else if (value.value === 'No Ceilometer') {
            return { key, value: value.value, units: value.units }
          } else {
            const val = value.value.HeightAGL.value
            const units = value.value.HeightAGL.units
            heightAGL = `${capitalizeStr(value.value.SkyCondition)} at ${
              typeof value === 'string' ? val.toString() : val
            } ${units}`
          }
        }
        return { key, value: heightAGL, units: value.units }
      }
    } else {
      return { key, value: 'N/A' }
    }
  })
  const order: string[] = [
    'Data',
    'timestamp',
    'Issue Time',
    'Valid Time From',
    'Valid Time To',
    'Valid Time',
    'Site',
    'STID',
    'ID',
    'Cloud Coverage 1',
    'Cloud Coverage 2',
    'Cloud Coverage 3',
    'Cloud Cover',
    'CloudLayer1',
    'CloudLayer2',
    'CloudLayer3',
    'Cloud Layer 1 Value',
    'Cloud Layer 2 Value',
    'Cloud Layer 3 Value',
    'WindSpeed',
    'Wind Speed',
    'Wind Direction',
    'WindDirection',
    'Visibility',
    'Temperature',
    'Dew Point Temperature',
    'DewpointTemperature',
    'Pressure',
    'Sea Level Pressure',
    'Raw TAF',
    'Raw Observation',
  ]
  keys = keys.filter((value) => !order.includes(value))
  const finalTableData = []
  for (const key of order) {
    const value = tableData.find((item) => item.key === key)
    if (value) {
      finalTableData.push(value)
    }
  }
  for (const key of keys) {
    const value = tableData.find((item) => item.key === key)
    if (value) {
      finalTableData.push(value)
    }
  }
  return (
    <div className="bg-white min-w-full shadow-md rounded my-6">
      <table className="leading-normal">
        <thead>
          <tr>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-gray-600 text-left text-sm uppercase font-normal">
              Property
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-gray-600 text-left text-sm uppercase font-normal">
              Value
            </th>
          </tr>
        </thead>
        <tbody>
          {finalTableData.map(
            (item, index) =>
              item.value != undefined &&
              item.value != '' &&
              item.value !== 'N/A' && (
                <tr key={index}>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {item.key}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {item.value} {item.units || ''}
                  </td>
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  )
}

export default MapPopupTable
