import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchPIREPData } from "./PIREPAPI";

import { PirepParsedData } from "../../interfaces/pirep";

  const initialState = {
    data: [] as PirepParsedData[],
    status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
    error: null,
  };
  export const getPirepData = createAsyncThunk(
    'location/addNewLocation',
    async () => {
      const response = await fetchPIREPData()
      return response
    }
  )
  
  const pirepSlice = createSlice({
    name: 'pirep',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getPirepData.pending, (state) => {
          state.status = 'loading';
          state.error = null;
        })
        .addCase(getPirepData.fulfilled, (state, action) => {
          state.status = 'succeeded';
        })
        .addCase(getPirepData.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });
    },
  });
  
  export default pirepSlice.reducer;


