import React from 'react';
import ArrowUp from '../../assets/arrow-up.svg'; // Import your ArrowUp component if needed

function Footer() {
  return (
    <div className='flex justify-center items-center flex-row w-full h-12 bg-slate-gradient' style={{ position: 'fixed', bottom: 0, height: '6vh' }}>
      <div className='flex items-center mx-4'>
        <a href="https://truweathersolutions.com/" target="_blank" rel="noopener noreferrer" className='text-black tracking-wider'>
          TruWeather Solutions
        </a>
        <img
          src={ArrowUp} // If ArrowUp is an external component, make sure to import it
          width="20"
          height="20"
        />
      </div>
      <div className='flex items-center mx-4'>
        <a href="https://truflitev360.com/assets/t&c.pdf" target="_blank" rel="noopener noreferrer" className='text-black tracking-wider'>
          Terms of Use
        </a>
        <img
          src={ArrowUp} // If ArrowUp is an external component, make sure to import it
          width="20"
          height="20"
        />
      </div>
    </div>
  );
}

export  {Footer};
