import React, { createContext, useContext, ReactNode, useState } from 'react';
import { SurfaceObsSensors } from '../../utils/SurfaceObsUtils';


interface SlideOverDialogContextType {
  totalProducts: string[];
  setProductVisibility: string[];
  addProductVisibility: (productName: string) => void;
  removeProductVisibility: (productName: string) => void;
  setTotalProducts: React.Dispatch<React.SetStateAction<string[]>>;
}

const SlideOverDialogContext = createContext<SlideOverDialogContextType | undefined>(undefined);

export function SlideOverDialogProvider({ children }: { children: ReactNode }) {
  const [setProductVisibility, setSetProductVisibility] = useState<string[]>(SurfaceObsSensors);
  const [totalProducts, setTotalProducts] = useState<string[]>(SurfaceObsSensors);

  const addProductVisibility = (productName: string) => {
    setSetProductVisibility((prevVisibility) => [...prevVisibility, productName]);
  };

  const removeProductVisibility = (productName: string) => {
    setSetProductVisibility((prevVisibility) => prevVisibility.filter((product) => product !== productName));
  };



  return (
    <SlideOverDialogContext.Provider value={{ totalProducts,setProductVisibility,  addProductVisibility,removeProductVisibility,setTotalProducts}}>
      {children}
    </SlideOverDialogContext.Provider>
  );
}

export function useSlideOverDialogContext() {
  const context = useContext(SlideOverDialogContext);
  if (!context) {
    throw new Error('useSlideOverDialogContext must be used within a SlideOverDialogProvider');
  }
  return context;
}
