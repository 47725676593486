import { ReactNode, CSSProperties, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import indicator from '../../../assets/alert.svg'
import AlertNotificationBox from '../../../Components/Alerting/AlertingBox/AlertNotificationBox'
import { callNetworkAdapter, sideNavRefresh } from '../../../Components/Alerting/AlertingSlice/AlertingSlice'
import Toast, {
  toastPosition,
  toastType,
} from '../../../Components/Toast/Toast'
import { selectedItems } from '../../SideNavigation/SideNavigationSlice'

type Props = {
  style?: CSSProperties
  children?: ReactNode
  alertnumber?: number
}

export default function AlertIndicator(props: Props) {
  const dispatch=useDispatch();
  const [isAlertBoxOpen, setIsAlertBoxOpen] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const handleButtonClick = () => {
    if (props.alertnumber === 0) {
      setShowToast(true)
    } 
    if(!isAlertBoxOpen){
      dispatch(selectedItems('Alertss'));
      dispatch(sideNavRefresh())
      dispatch(callNetworkAdapter(true));
    }
    else{
      dispatch(selectedItems('Alertss'));
      dispatch(callNetworkAdapter(false));
    }
    setIsAlertBoxOpen(!isAlertBoxOpen)
    }
  const handleCloseAlertBox = () => {
    setIsAlertBoxOpen(false)
  }
  useEffect(() => {
    if (showToast) {
      const toastTimeout = setTimeout(() => {
        setShowToast(false)
      }, 3000)
      return () => {
        clearTimeout(toastTimeout);
      };
    }
  }, [showToast])

  let badge = <></>

  if (typeof props.alertnumber !== 'undefined' && props.alertnumber > 0) {
    badge = (
      <div className="absolute bg-red-600 w-4 h-4 text-white rounded-full text-xs p-0 text-center z-10 flex items-center justify-center">
        {props.alertnumber}
      </div>
    )
  }

  if (props.alertnumber > 0) {
    return (
      <div
        className="self-center pr-3 ml-0"
        title={props.alertnumber + ' alerts'}
      >
        <button className="relative" onClick={handleButtonClick}>
          {badge}
          <img className="invert" src={indicator} alt="" style={{ filter: 'brightness(0) invert(1)' }}/>
        </button>
        {isAlertBoxOpen && (
          <AlertNotificationBox
            alertnumber={props.alertnumber}
            onDismissAlert={props.onDismissAlert}
            onClose={handleCloseAlertBox}
            dismissedAlerts={props.dismissedAlerts}
            properties={props.props}
          />
        )}
      </div>
    )
  } else {
    return (
      <div
        className="self-center pr-3 ml-0"
        title={`${props.alertnumber} alerts`}
      >
        <button className="relative" onClick={handleButtonClick}>
          {badge}
          <img className="invert" src={indicator} alt="" style={{ filter: 'brightness(0) invert(1)' }}/>
        </button>
        {showToast}
      </div>
    )
  }
}
