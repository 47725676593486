import { METARAPIParser } from '../../utils/METARUtils'
import { TWS_API_URL } from '../../environment/apis.config'
import { timeouts } from '../RequestTimeouts'

export const fetchMETARData = async (
  defaultLocation: { latitude: any; longitude: any } | undefined
) => {
  const distance = 100
  const controller = new AbortController()

  return new Promise(async (resolve, reject) => {
    const timeoutId = setTimeout(() => {
      controller.abort()
      reject(new Error('METAR request timed out. Please try again.'))
    }, timeouts.metar)

    const response = await fetch(
      `${TWS_API_URL}/metarsAviation?location=${defaultLocation.latitude},${defaultLocation.longitude},${distance}`,
      {
        method: 'GET',
        headers: {
          // Authorization: `Bearer ` + sessionStorage.getItem('token'),
          Authorization: `Bearer ` + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
        signal: controller.signal,
      }
    )
    .then(res => {
      clearTimeout(timeoutId)
      if (!res.ok) {
        throw new Error("Failed to fetch METAR dara" /*reject(response.status*/)
      }
      return res.json()
    })
    .then(data => {
      resolve(METARAPIParser(data))
    })
    .catch(error => {
      if (controller.signal.aborted) {
        reject( new Error('METAR request aborted.'))
      } else {
        reject(error)
      }
    });
  })
}

export default fetchMETARData
