// Wrapper around a Terrier layer
export default class Layer {
  constructor(
    terrierOvl,
    displayName,
    layerName,
    levels,
    units,
    colorsGrey,
    colorsNotGrey,
    opacity,
    timeRange,
    importanceScale,
    curLevel = [],
    source,
    loadCallback,
    startFrame,
    dataSampleType = 1
  ) {
    // Parameters
    this.terrierOvl = terrierOvl
    this.displayName = displayName
    this.layerName = layerName // 'temperature', 'windUV', or 'radar'
    this.levels = levels
    this.level =
      curLevel.length == 0 ? curLevel[0] : levels.length == 0 ? null : levels[0]
    this.units = units // temp = 'K' / 'F' / 'C'    wind = 'm/s'    radar = 'dBz'
    this.colorsGrey = colorsGrey
    this.colorsNotGrey = colorsNotGrey
    // Default variables
    this.colored = true
    this.dataSampleType = dataSampleType // 0 = Nearest, 1 = Linear, 2 = Cubic.
    this.opacity = opacity // 0 - 255.
    this.minImportance = importanceScale !== undefined ? importanceScale : 1 // 5 - 100.

    this.timeRange = timeRange
    this.startFrame =startFrame
    this.layer = null
    this.loadCallback=loadCallback
    if (source) {
      this.source = source
    } else {
      this.source = null
    }
  }

  enable(onOff) {
    if (onOff) {
      if (this.layer == null) {
        const params = {}
        if (this.level !== undefined) {
          params['level'] = this.level
        }
        params['renderScale'] = 0.5
        params['cadence'] = this.timeRange
        params['startFrame'] = this.startFrame
        if (this.loadCallback) {
          params['loadCallback'] = this.loadCallback
        }
        if (this.source) {
          params['source'] = this.source
          this.layer = this.terrierOvl.startLayer('visual', params)
        } else {
          this.layer = this.terrierOvl.startLayer(this.layerName,params)
        }

        this.dataSampleUpdate(this.dataSampleType)
        this.opacityUpdate(this.opacity)
        this.minImportanceUpdate(this.minImportance)
        this.setLevel(this.level)
        this.colorUpdate(true)
      }
    } else {
      if (this.layer != null) {
        this.terrierOvl.stopLayer(this.layer)
        this.layer = null
      }
    }
    if (onOff) {
      if (onOff) {
        this.colorUpdate(this.colored);
        this.dataSampleUpdate(this.dataSampleType);
        this.opacityUpdate(this.opacity);
        this.minImportanceUpdate(this.minImportance);
      }
    }
  }

  colorUpdate(isNowColored) {
    let newShaderColorMap

    if (isNowColored) {
      newShaderColorMap = this.colorsNotGrey
    } else {
      newShaderColorMap = this.colorsGrey
    }

    if (this.layer != null) {
      this.layer.setColorMap(newShaderColorMap)
    }

    this.colored = isNowColored
  }

  dataSampleUpdate(n) {
    this.dataSampleType = n

    this.layer.setInterpMode(['nearest', 'linear', 'cubic'][n])
  }

  opacityUpdate(n) {
    this.opacity = n

    // TODO: Make this from 0 to 1
    if (this.layer != null) {
      this.layer.setOpacity(n / 255)
    }
  }

  minImportanceUpdate(n) {
    this.minImportance = n

    if (this.layer != null) {
      this.layer.setImportanceScale(Math.min(32, Math.max(1, n)))
    }
  }

  // Change the level being displayed
  setLevel(newLevel) {
    if (this.layer != null) {
      this.layer.setLevel(newLevel)
    }
    this.level = newLevel
  }

  getDisplayName() {
    return this.displayName
  }

  getIcon() {
    return this.icon
  }

  getColorMap() {
    if (this.colored) {
      return this.colorsNotGrey
    } else {
      return this.colorsGrey
    }
  }

  getUnits() {
    return this.units
  }

  // Return available time range
  getTimeRange() {
    return this.timeRange
  }
  setTimeRange(timeRange) {
    this.terrierOvl.setCadence(timeRange)
  }
  queryValue(x,y) {
    if (this.layer == null) {
      return null
    }

    return this.layer.queryValue(x,y)
  }
}
