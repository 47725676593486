import { TWS_API_URL } from '../../environment/apis.config'
import { AIRMETAPIParser } from '../../utils/AIRMETUtils'
import { timeouts } from '../RequestTimeouts'

export const fetchAIRMETData = async (
  defaultLocation: { latitude: any; longitude: any } | undefined
) => {
  const timeoutId = setTimeout(() => {}, timeouts.airmet)
  return new Promise(async (resolve, reject) => {
      const response = await fetch(
        `${TWS_API_URL}/airmet?lat=${defaultLocation.latitude}&long=${defaultLocation?.longitude}`,
        {
          method: 'GET',
          headers: {
            // Authorization: `Bearer ` + sessionStorage.getItem('token'),
            Authorization: `Bearer ` + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        }
      )
      .then(res => {
         clearTimeout(timeoutId)
         if (!res.ok) {
             throw new Error("Failed to fetch AIRMET data")
         }
         return res.json()
      })
      .then(data => {
        resolve(AIRMETAPIParser(data))
      })
      .catch(error => {
         if (error.name === 'AbortError') {
             reject(new Error('AIRMET request timeout.'))
         } else {
             reject(error)
         }
      })
  })
}

export default fetchAIRMETData
