/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { ReactNode, CSSProperties, useState, useRef, useEffect } from 'react'
import './TopNavigation.css'
import twsLogoFull from '../../assets/TWS-Logo.svg'
import twsLogoSmall from '../../assets/TWS-Logo-sm.svg'
import grandSkyLogo from '../../assets/grandSky-icon.svg'
import grandSkyLogoSml from '../../assets/grandSky-icon.svg'
// import SearchInput from './SearchInput/SearchInput'
import LocationIndicator from './LocationIndicator/LocationIndicator'
import AlertIndicator from './AlertIndicator/AlertIndicator'
import ProfileMenu from '../ProfileMenu/ProfileMenu'
import { useDispatch, useSelector } from 'react-redux'
import { expand, collapse } from '../SideNavigation/SideNavigationSlice'
import { Link } from 'react-router-dom'
import AlertSetting from '../../Components/Alerting/AlertSetting/AlertSetting'
import { RootState } from '../../store'
import { ApiLoader } from '../../Components/Spinner/ApiLoader'

type Props = {
  children?: ReactNode
  className?: string
  style?: CSSProperties
  burgerHandler: (menuExpanded: boolean) => void
}

export default function TopNavigation(props: Props) {
  
  const [numberOfAlerts, setNumberOfAlerts] = useState(0)
  const [smallLogo, setSmallLogo] = useState(false)
  const burgerRef = useRef<SVGSVGElement>(null)
  const dispatch = useDispatch()
  const [dismissedAlerts] = useState<number>(0)
  const apiLoader = useSelector((state: RootState) => state.apiLoading)
  // const handleDismissAlert = () => {
  //   setDismissedAlerts((prevDismissedAlerts) => prevDismissedAlerts + 1)
  // }
  const dummyBurgerHandler = function (menuExpanded: boolean) {
    // console.log('>>>> ', menuExpanded)
    ()=>{}
  }
  const settingAlertNotification = useSelector(
    (state: RootState) => state.alert.setAlertingNotification
  )
  const showAlertCreate = useSelector(
    (state: RootState) => state.alert.showAlertSETTINGCreate
  )
  const removeAlert = useSelector((state: RootState) => state.alert.removeAlert)
  // if (settingAlertNotification != '') {
  //   setNumberOfAlerts(numberOfAlerts + settingAlertNotification.length - removeAlert.length)
  // }
  const alertSettingedit = useSelector(
    (state: RootState) => state.alert.showAlertSETTINGEdit
  )
  const selectedP = useSelector((state: RootState) => state.alert.setProperties)

  const expandedState = useSelector(
    (state: RootState) => state.sideNav.expanded
  )
  const [menuExpanded, setMenuExpanded] = useState(!expandedState)
  useEffect(() => {
    if (settingAlertNotification != '') {
      setNumberOfAlerts(settingAlertNotification.length - removeAlert.length)
    }else{
      setNumberOfAlerts(0)
    }
  },[settingAlertNotification,removeAlert])
  // Sync local state with Redux state
  useEffect(() => {
    setMenuExpanded(expandedState)

    const handleResize = () => {
      const windowWidth = window.innerWidth

      if (windowWidth <= 1000) {
        setSmallLogo(true)
      } else {
        setSmallLogo(false)
      }
    }

    // Add the event listener when the component mounts
    window.addEventListener('resize', handleResize)

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [expandedState])
  const reseller = useSelector((state: RootState) => state.user.reseller)

  const expandMenu = () => {
    const burgerElement = burgerRef.current
    if (menuExpanded) {
      burgerElement?.classList?.remove('active')
      burgerElement?.classList?.add('inactive')
      dispatch(collapse())
    } else {
      burgerElement?.classList?.remove('inactive')
      burgerElement?.classList?.add('active')
      dispatch(expand())
    }
    props.burgerHandler(!menuExpanded)
  }

  const burger = (
    <svg
      ref={burgerRef}
      onClick={expandMenu}
      className="ham hamRotate ham1 active"
      viewBox="0 0 100 100"
      width="48"
      fill="#000"
      style={{ filter: 'brightness(0) invert(1)' }}
    >
      <path
        fill="#000"
        className="line top"
        d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"
      />
      <path fill="#000" className="line middle" d="m 30,50 h 40" />
      <path
        fill="#000"
        className="line bottom"
        d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"
      />
    </svg>
  )
  let logo: ReactNode

  if (!menuExpanded) {
    if (reseller?.reseller == 'GrandSky') {
      logo = (
        <Link to="/map">
          <div
            style={{
              width: '55px',
              height: '42px',
              display: 'inline-block',
              backgroundColor: 'black',
            }}
          >
            <img
              style={{ width: '55px', height: '42px', margin: '12px 0 0 2px' }}
              src={grandSkyLogoSml}
              alt="logo"
            />
          </div>
        </Link>
      )
    } else {
      logo = (
        <Link to="/map">
          <div
            style={{ width: '55px', height: '42px', display: 'inline-block' }}
          >
            <img
              style={{ height: '42px', width: '55px', margin: '12px 0 0 2px' }}
              src={twsLogoSmall}
              alt="logo"
            />
          </div>
        </Link>
      )
    }
  } else {
    if (reseller?.reseller == 'GrandSky') {
      logo = (
        <Link to="/map">
          <div
            style={{
              width: smallLogo ? '55px' : '260px',
              height: '42px',
              display: 'inline-block',
              backgroundColor: 'black',
            }}
          >
            <img
              style={{
                width: smallLogo ? '55px' : '260px',
                height: '42px',
                margin: '12px 0 0 2px',
              }}
              src={smallLogo ? grandSkyLogoSml : grandSkyLogo}
              alt="logo"
            />
          </div>
        </Link>
      )
    } else {
      logo = (
        <Link to="/map">
          <div
            style={{
              width: smallLogo ? '55px' : '260px',
              height: '42px',
              display: 'inline-block',
            }}
          >
            <img
              style={{ height: '42px', margin: '12px 0 0 2px' }}
              src={smallLogo ? twsLogoSmall : twsLogoFull}
              alt="logo"
            />
          </div>
        </Link>
      )
    }
  }
  return (
    <>
      <div
        className="flex basis-16 grow-0 shrink-0 w-full bg-black overflow-y-hidden z-[9999999] min-w-min"
      >
        {logo}
        <div className="w-full flex justify-between ml-0 overflow-y-hidden">
          <div className="inline-block w-14 mt-2" title="Expand/Collapse Menu">
            {burger}
          </div>
          {/*<SearchInput></SearchInput>*/}
          <LocationIndicator></LocationIndicator>
          {apiLoader ? <ApiLoader/> : null}
          <div className="flex">
            <AlertIndicator
              // onDismissAlert={handleDismissAlert}
              burgerHandler={dummyBurgerHandler}
              alertnumber={numberOfAlerts}
              dismissedAlerts={dismissedAlerts}
              props={settingAlertNotification}
            />
            <ProfileMenu></ProfileMenu>
          </div>
        </div>
      </div>
      <div className="ml-40">
        {alertSettingedit && <AlertSetting properties={selectedP} />}
        {showAlertCreate ? <AlertSetting isEditing={undefined} /> : null}
      </div>
    </>
  )
}
