import L from 'leaflet'

interface TWSOpacitySliderOptions extends L.ControlOptions {
  title?: string
  tileLayer?: L.TileLayer | null
  position: 'topleft' | 'topright' | 'bottomleft' | 'bottomright'
  opacity: number
}

const TWSOpacitySlider = L.Control.extend({
  options: {
    title: '' as string,
    tileLayer: null,
    position: 'topright',
    opacity: 0.5,
  } as TWSOpacitySliderOptions,
  tileLayer: null as L.TileLayer | null,
  currentValue: 0.0,

  initialize: function (options: TWSOpacitySliderOptions) {
    L.Util.setOptions(this, options)
    if (options.opacity && options.opacity !== null) {
      this.currentValue = options.opacity
    }

    // set the position of the control
    if (options.position && options.position !== null) {
      this.setPosition(options.position)
    }

    if (options.tileLayer) {
      this.tileLayer = options.tileLayer
    }
  },
  onAdd: function (map) {
    // create the DOM elements
    const container = L.DomUtil.create(
      'div',
      'leaflet-control leaflet-control-custom bg-white flex flex-col justify-center items-center py-2 px-4'
    )
    const caption = L.DomUtil.create(
      'span',
      'opacity-slider-caption',
      container
    )
    const slider = L.DomUtil.create('input', 'opacity-slider', container)
    slider.value = this.options.opacity.toString()
    // this._slider = slider
    caption.innerHTML = this.options.title
    slider.type = 'range'
    slider.min = '0'
    slider.max = '1'
    slider.step = '0.01'
    // slider.value = '0.2'
    slider.value = this.options.opacity.toString()
    this.currentValue = parseFloat(slider.value)
    if (this.tileLayer) {
      this.tileLayer.setOpacity(parseFloat(slider.value))
    }
    L.DomEvent.disableClickPropagation(slider)

    // listener for when the slider is changed
    L.DomEvent.on(slider, 'change', (e) => {
      const inputElement = e.target as HTMLInputElement
      this.currentValue = parseFloat(inputElement.value)
      // Update tile layer opacity if a tileLayer is available
      if (this.tileLayer) {
        this.tileLayer.setOpacity(this.currentValue)
      }
    })
    return container
  },
  setTileLayer: function (tileLayer: L.TileLayer) {
    this.tileLayer = tileLayer
  },
  onRemove: function (map) {
    const container = this._container as HTMLElement
    // L.DomEvent.off(this._slider, 'click')
    L.DomUtil.remove(container)
  },
})

export default TWSOpacitySlider
