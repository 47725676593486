import { useState } from 'react';
import 'flowbite';
import { useDispatch, useSelector } from 'react-redux';
import { callNetworkAdapter, hideAlertSETTING, hideAlertSETTINGEdit, showAlertSETTING, showAlertSETTINGEdit } from '../AlertingSlice/AlertingSlice';
import useAlertSetting from '../useAlertSetting';
import { elements } from '../../../interfaces/alertingelements'
import { selectedItems } from '../../../menus/SideNavigation/SideNavigationSlice';


type Alert = {
    id: number;
    formattedDate: string;
    formattedTime: string;
};
let allEvents: {
    key: number,
    endTime: string,
    summary: string,

}

const AlertNotificationBox = (props) => {
    const propertyElements = Object.entries(props.properties).map(([key, value]) => ({
        key: key,
        value: value
    }));
    const { dismissAlert, addOnlyNotificationAlert, removeAlertNotification,removingNotificationAlert} = useAlertSetting();
    let displayingAlerts = [];
    (propertyElements.forEach((element) => {
        if(!removeAlertNotification?.includes(element?.value?.properties?.id)){
            displayingAlerts.unshift(element.value.properties);
        }
    }));
    const dispatch = useDispatch();
    const [currentAlertIndex, setCurrentAlertIndex] = useState(0);
    addOnlyNotificationAlert(displayingAlerts[currentAlertIndex]?.id);
    const handlePrevAlert = () => {
        setCurrentAlertIndex((prevIndex) =>
            prevIndex === 0 ? displayingAlerts.length - 1 : prevIndex - 1
        );
    };

    const handleNextAlert = () => {
        setCurrentAlertIndex((prevIndex) => (prevIndex + 1) % displayingAlerts.length);
    };
    const handleCloseAlert = () => {
        props.onClose();
        dispatch(hideAlertSETTING());
        dispatch(hideAlertSETTINGEdit());
    };
    const [showSettings, setShowSettings] = useState(false);
    let shouldDisplay='';
    const handleShowSettingsClick = () => {
        setShowSettings(!showSettings);
        if (!showSettings) {
            dispatch(showAlertSETTING());
            dispatch(showAlertSETTINGEdit());
            openAlertSetting(displayingAlerts[currentAlertIndex])
            closeAlertSetting();
        }
        else if (showSettings) {
            dispatch(hideAlertSETTING());
            dispatch(hideAlertSETTINGEdit());
        }
    };
    const { selectedProperties, openAlertSetting, closeAlertSetting } = useAlertSetting();

    const handleDismissAlert = () => {
        if(displayingAlerts?.length==1){
            dispatch(selectedItems('Alertss'));
            dispatch(callNetworkAdapter(false))
        }
        dismissAlert(displayingAlerts[currentAlertIndex]?.id);
        removingNotificationAlert(displayingAlerts[currentAlertIndex]?.id)
        setCurrentAlertIndex(0);
        dispatch(hideAlertSETTING());
        dispatch(hideAlertSETTINGEdit());
      };
    const alertBoxContent = (
        <>
            <div className="bg-gray-100 text-black p-2 flex items-center" style={{ borderRadius: '10px 10px 0px 0px' }}>
                <span className="font">Alert</span>
                <button
                    className="ml-auto bg-transparent text-black border-none cursor-pointer text-sm"
                    onClick={handleCloseAlert}
                >
                    x
                </button>
            </div>
            <hr />
            <div className="p-2 text-center">
                {displayingAlerts[currentAlertIndex]?.validAt}
            </div>
            <div className="bg-yellow-700 text-black p-3 text-center text-sm h-32 overflow-y-scroll">
                {displayingAlerts[currentAlertIndex]?.triggerText}
            </div>
            <div className="flex justify-between mt-4">
                <button
                    className="prev-arrow mt-0 bg-white hover:bg-gray-300 text-black font-semibold px-3 py-2 mx-1 my-5 rounded"
                    onClick={handlePrevAlert}
                >
                    {'<'}
                </button>
                <button
                    className="bg-green-500 text-white px-4 py-2 text-center mx-1 my-5 mt-4 rounded-md"
                    onClick={handleDismissAlert}
                >
                    Dismiss Alert
                </button>
                <button
                    className="next-arrow mt-0 bg-white hover:bg-gray-300 text-black font-semibold px-3 py-2 mx-1 my-5 rounded"
                    onClick={handleNextAlert}
                >
                    {'>'}
                </button>
            </div>
            <hr className="mt-2" />
            {/* <button
                className="text-black text-sm px-20 py-2 text-center mt-0 rounded-md flex mt-2 ml-3"
                onClick={handleShowSettingsClick}
            >

                {showSettings ? 'Hide Alert Settings' : 'Change Alert Settings'}

            </button> */}

            {elements.map((element) => {
                     if(displayingAlerts[currentAlertIndex]?.hazardType === element.value ){
                         shouldDisplay=''
                        shouldDisplay+=(element.id)
                     }
                })}
            <div className="rounded-md p-2 mt-4">
                <div
                    className={`text-black text-sm px-2 py-1 text-center ml-4 mr-4 ${displayingAlerts[currentAlertIndex]?.mag <= 30 ? 'bg-red-500' : 'bg-red-500'
                        }`}
                >
                    {shouldDisplay} threshold is {displayingAlerts[currentAlertIndex]?.comparison} {" "} {parseFloat(displayingAlerts[currentAlertIndex]?.eventThreshold).toFixed(2)} {displayingAlerts[currentAlertIndex]?.thresholdUnits}
                </div>
            </div>
        </>
    );
    return (
        <div className="fixed bottom-60 left-55 right-60 top-11 w-80 h-96 border bg-white border-gray-300 rounded-md flex flex-col" style={{ borderRadius: '10px 10px 10px 10px', zIndex: 1000 }}>
            {alertBoxContent}
        </div>
    );
};
export default AlertNotificationBox;
