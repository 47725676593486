import { useEffect, useRef, useState } from 'react'
import Layer from '../../utils/Layers'
import '../../utils/L.RealtimeCanvasLayer.js'
import Terrier from '../../utils/terrier'
import { useMap, useMapEvent, Tooltip } from 'react-leaflet'
import MediaControls from './Media Controls/MediaControls'
import Legend from './Legend/Legend'
import { useDispatch, useSelector } from 'react-redux'
import { setCurLayer } from './WetDogWeatherProductsSlice'
import { WDWSTACK } from '../../environment/apis.config'
import L from 'leaflet'
import {
  LayerToUnitsMapping,
  convertValuesToTruweatherUnits,
  formatValue,
} from '../../utils/WetDogWeatherUtils'
import useErrorStatus from '../../hooks/UseErrorStatus'

const WetDogWeatherLayers = () => {
  const [legendVisible, setLegendVisible] = useState(true)
  const [layers, setLayers] = useState<Layer[]>([])
  const [level, setLevel] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [animSpeed, setAnimSpeed] = useState(5.0)
  const [timeRange, setTimeRange] = useState([0.0, 0.0])
  const [curTime, setCurTime] = useState(Number.NEGATIVE_INFINITY)
  const [displayedTime, setDisplayedTime] = useState(Number.NEGATIVE_INFINITY)
  const [terrierOvl, setTerrierOvl] = useState(null)
  const [units, _setUnits] = useState('')
  const [canvasLayer] = useState(L.realtimeCanvasLayer())
  const [snapFrame] = useState(true)

  const canvasRef = useRef(null)
  const map = useMap()
  const curLayer = useSelector((state) => state.WetDogWeatherProducts.curLayer)
  const dispatch = useDispatch()
  const errorStatus = useErrorStatus()

  const startUpFunc = () => {
    if (map !== null) {
      Terrier.startLeaflet(WDWSTACK, canvasLayer, (ovl) => {
        terrierReady(ovl)
      })

      canvasLayer.addTo(map)
    }
  }

  useEffect(() => {
    for (let layerId = 0; layerId < layers.length; layerId++) {
      if (layerId != curLayer.layer) {
        layers[layerId].enable(false)
      }
    }
    const now = new Date().getTime() / 1000

    // Then turn ours on
    if (curLayer.layer >= 0 && curLayer.layer < layers.length) {
      const layer = layers[curLayer.layer]
      layer.enable(true)
      let timeRange = layer.layer.ovl.getTimeRange()
      timeRange = layer.timeRange
      setTimeRange([now + timeRange[0], now + timeRange[1]])
      if (curTime < timeRange[0] + now) {
        setCurTime(timeRange[0] + now)
      } else if (curTime >= timeRange[1] + now) {
        setCurTime(timeRange[1] + now)
      }

      // And update the units of whatever is being displayed
      _setUnits(layer.units)
      layers[curLayer.layer].getDisplayName() === 'Wind80m'
        ? layers[curLayer.layer].setLevel('80m')
        : layers[curLayer.layer].setLevel(null)
    } else {
      setCurTime(now)
      setTimeRange([0.0, 0.0])
      _setUnits('')
      setLevel(null)
    }
  }, [curLayer.layer])

  useEffect(() => {
    if (terrierOvl) {
      terrierOvl.setNearestFrame(snapFrame)
    }
  }, [snapFrame])

  //react to curTime changes
  useEffect(() => {
    if (curTime == Number.NEGATIVE_INFINITY || curTime === undefined) {
      return
    }
    if (terrierOvl == undefined) {
      return
    }
    terrierOvl?.setCurrentTime(curTime)
    setDisplayedTime(terrierOvl.getCurrentTime())
  }, [curTime])

  useEffect(() => {
    if (terrierOvl == undefined) {
      return
    }
    if (isPlaying) {
      terrierOvl.timePlay({ period: 30.0 / animSpeed, pause: 2.0 })
    } else {
      terrierOvl.timePause()
      // We were animating, so update our curTime from Terrier
      setCurTime(terrierOvl.getCurrentTime())
    }
  }, [isPlaying, animSpeed])

  const updatePlayTime = () => {
    if (terrierOvl == undefined) {
      return
    }
    const newTime = terrierOvl.getCurrentTime()
    if (displayedTime != newTime) {
      // TODO: Check that we're not creating a slow recursion here
      setDisplayedTime(newTime)
    }
  }
  useEffect(() => {
    const interval = setInterval(() => updatePlayTime(), 100)
    return () => {
      clearInterval(interval)
    }
  }, [isPlaying, curTime, terrierOvl])

  const terrierReady = (ovl) => {
    setTerrierOvl(ovl)
    // Clean up any existing layers
    layers.forEach((layer) => {
      layer.enable(false)
    })
    const feetToMeters = 1 / 3.28084
    const cloudColorMap = Terrier.createColorMap(
      [
        0.0 * feetToMeters,
        500.0 * feetToMeters,
        900.0 * feetToMeters,
        1000.0 * feetToMeters,
        3000.0 * feetToMeters,
        4000.0 * feetToMeters,
        5000.0 * feetToMeters,
      ],
      [
        0xff800000, 0xffff0000, 0xffffff00, 0xffff6600, 0xff000080, 0xff003300,
        0xff006400,
      ]
    )

    const statMileToMeters = 1609.34
    const visColorMap = Terrier.createColorMap(
      [
        0,
        1 * statMileToMeters,
        3 * statMileToMeters,
        5 * statMileToMeters,
        7 * statMileToMeters,
        10 * statMileToMeters,
      ],
      [0xff800000, 0xffda0000, 0xffffff00, 0xff00ff00, 0xff003300, 0xff0000]
    )

    const metersToKnots = 1 / 1.94384
    const windColorMap = Terrier.createColorMap(
      [
        0,
        5 * metersToKnots,
        9 * metersToKnots,
        16 * metersToKnots,
        25 * metersToKnots,
        35 * metersToKnots,
        40 * metersToKnots,
      ],
      [
        0xff0000, 0xff00cc05, 0xffecf006, 0xffe11511, 0xff800000, 0xffe111c1,
        0xffffcef7,
      ]
    )
    // Set up the layers we know about and enable the first one
    const newLayers = [
      new Layer(
        ovl,
        'Radar',
        'radar',
        Terrier.variableLevelsForStack('radar'),
        'dBz',
        Terrier.RADAR_COLORS_GREY,
        Terrier.RADAR_COLORS_NOT_GREY,
        120,
        [-2 * 60 * 60, 0, 64],
        16.0,
        [],
        null,
        (manifest) => {
          // Ignore everything but the biggest region
          if (manifest.region != 'conus') {
            return
          }

          // The manifest has a list of time slices which we can interrogate
          const firstSlice = manifest.timeSlices[0]
          const lastSlice = manifest.timeSlices.slice(-1)[0]

          // Construct a new relative time range to display
          // Snap to the available time slices
          const newTimeRange = [
            firstSlice.forecastEpoch,
            lastSlice.forecastEpoch,
          ]
          ovl.setTimeRange(newTimeRange[0] * 1000, newTimeRange[1] * 1000)
          setTimeRange(newTimeRange)

          // And snap to the end for the current time
          ovl.setCurrentTime(lastSlice.forecastEpoch)
        },
        'last',
        0
      ),
      new Layer(
        ovl,
        'visual',
        'visual',
        null,
        'dBz',
        null,
        null,
        255,
        [0.0, 60 * 60, 12],
        32.0,
        [],
        {
          model: 'myradar',
          region: 'global',
          variable: 'reflectivity',
        },
        (manifest) => {
          // Ignore everything but the biggest region
          if (manifest.region != 'global') {
            return
          }
          // The manifest has a list of time slices which we can interrogate
          const lastSlice = manifest.timeSlices.slice(-1)[0]

          // Construct a new relative time range to display
          // Snap to the available time slices
          const now = Date.now() / 1000
          const newTimeRange = [now, lastSlice.forecastEpoch]
          ovl.setTimeRange(newTimeRange[0] * 1000, newTimeRange[1] * 1000)
          setTimeRange(newTimeRange)
          // And snap to the end for the current time
          ovl.setCurrentTime(now)
        },
        'current'
      ),
      //forecast layers
      new Layer(
        ovl,
        'Cloud Ceiling',
        'CloudCeiling',
        Terrier.variableLevelsForStack('CloudCeiling'),
        'ft',
        cloudColorMap,
        cloudColorMap,
        140,
        // timeRange,
        [0.0, 1 * 24 * 60 * 60, 32],
        // [0, 0],
        16,
        [],
        null,
        (manifest) => {},
        'current'
      ),
      new Layer(
        ovl,
        'Visibility',
        'Visibility',
        Terrier.variableLevelsForStack('visibility'),
        'sm',
        visColorMap,
        visColorMap,
        140,
        // timeRange,
        [0.0, 1 * 24 * 60 * 60, 32],
        // [0, 0],
        16,
        [],
        null,
        (manifest) => {},
        'current'
      ),
      new Layer(
        ovl,
        'Temperature',
        'temperature',
        Terrier.variableLevelsForStack('temperature'),
        'F',
        Terrier.TEMP_COLORS_GREY,
        Terrier.TEMP_COLORS_NOT_GREY,
        140,
        // timeRange,
        [0.0, 1 * 24 * 60 * 60, 32],
        // [0, 0],
        16,
        [],
        null,
        (manifest) => {},
        'current'
      ),
      new Layer(
        ovl,
        'Wind',
        'windUV',
        Terrier.variableLevelsForStack('wind_uv'),
        'kts',
        windColorMap,
        windColorMap,
        200,
        // timeRange,
        [0.0, 1 * 24 * 60 * 60, 32],
        // [0, 0],
        16,
        [],
        null,
        (manifest) => {},
        'current'
      ),
      new Layer(
        ovl,
        'Wind80m',
        'windUV',
        Terrier.variableLevelsForStack('wind_uv'),
        'kts',
        windColorMap,
        windColorMap,
        200,
        // timeRange,
        [0.0, 1 * 24 * 60 * 60, 32],
        // [0, 0],
        16,
        ['80m'],
        null,
        (manifest) => {},
        'current'
      ),
      new Layer(
        ovl,
        'WindGust',
        'WindGust',
        Terrier.variableLevelsForStack('WindGust'),
        'kts',
        windColorMap,
        windColorMap,
        200,
        // timeRange,
        [0.0, 1 * 24 * 60 * 60, 32],
        // [0, 0],
        16,
        ['sfc'],
        null,
        (manifest) => {},
        'current'
      ),
      //current layers
      new Layer(
        ovl,
        'Current-CloudCeiling',
        'CloudCeiling',
        Terrier.variableLevelsForStack('CloudCeiling'),
        'ft',
        cloudColorMap,
        cloudColorMap,
        140,
        // timeRange,
        [-1 * 24 * 60 * 60, 0.0, 32],
        // [0, 0],
        16,
        [],
        null,
        (manifest) => {},
        'current'
      ),
      new Layer(
        ovl,
        'Current-Visibility',
        'Visibility',
        Terrier.variableLevelsForStack('visibility'),
        'sm',
        visColorMap,
        visColorMap,
        140,
        // timeRange,
        [-1 * 24 * 60 * 60, 0.0, 32],
        // [0, 0],
        16,
        [],
        null,
        (manifest) => {},
        'current'
      ),
      new Layer(
        ovl,
        'Current-Temperature',
        'temperature',
        Terrier.variableLevelsForStack('temperature'),
        'F',
        Terrier.TEMP_COLORS_GREY,
        Terrier.TEMP_COLORS_NOT_GREY,
        140,
        // timeRange,
        [-1 * 24 * 60 * 60, 0.0, 32],
        // [0, 0],
        16,
        [],
        null,
        (manifest) => {},
        'current'
      ),
      new Layer(
        ovl,
        'Current-Wind',
        'windUV',
        Terrier.variableLevelsForStack('wind_uv'),
        'kts',
        windColorMap,
        windColorMap,
        200,
        // timeRange,
        [-1 * 24 * 60 * 60, 0.0, 32],
        // [0, 0],
        16,
        [],
        null,
        (manifest) => {},
        'current'
      ),
      new Layer(
        ovl,
        'Current-Wind80m',
        'windUV',
        Terrier.variableLevelsForStack('wind_uv'),
        'kts',
        windColorMap,
        windColorMap,
        200,
        // timeRange,
        [-1 * 24 * 60 * 60, 0.0, 32],
        // [0, 0],
        16,
        ['80m'],
        null,
        (manifest) => {},
        'current'
      ),
      new Layer(
        ovl,
        'Current-WindGust',
        'WindGust',
        Terrier.variableLevelsForStack('WindGust'),
        'kts',
        windColorMap,
        windColorMap,
        200,
        // timeRange,
        [-1 * 24 * 60 * 60, 0.0, 32],
        // [0, 0],
        16,
        ['sfc'],
        null,
        (manifest) => {},
        'current'
      ),
    ]
    setLayers(newLayers)

    dispatch(
      setCurLayer({
        layer: -1,
        mode: '',
      })
    )
    _setUnits(newLayers[0].units)
    // ovl.setNearestFrame(true)
    const now = Date.now() / 1000
    setCurTime(now)
  }

  useEffect(() => {
    startUpFunc()
  }, [map])

  const canDisplayLegend =
    legendVisible && curLayer.layer >= 0 && curLayer.layer < layers.length

  const canDisplayMediaControls =
    curTime != Number.NEGATIVE_INFINITY &&
    layers.length > 0 &&
    curLayer.layer >= 0 &&
    curLayer.layer < layers.length

  const canDisplayCanvas = curLayer.layer >= 0 && curLayer.layer < layers.length

  useMapEvent('click', (e) => {
    const layers = Terrier.ovl.getLayers()
    if (layers.length > 0) {
      const layer = layers[0]
      if (layer.name === 'visual') {
        return
      } else {
        const x = window.devicePixelRatio * e.containerPoint.x
        const y = window.devicePixelRatio * e.containerPoint.y
        const ret = layer.queryValue(x, y)
        if (ret === null || !ret['value']) {
          return
        }
        let value = convertValuesToTruweatherUnits(
          ret['value'][0],
          LayerToUnitsMapping[layer.name]
        )
        value = formatValue(value, layer.name)
        new L.Popup()
          .setContent(
            `<div className=''>Location : ${
              e.latlng.lat.toFixed(5) + ', ' + e.latlng.lng.toFixed(5)
            }</div>
            <div>Value : ${
              value.toString() + ' ' + LayerToUnitsMapping[layer.name]
            }</div>`
          )
          .setLatLng(e.latlng)
          .openOn(map)
      }
    }
  })

  return (
    <div className={'w-full h-full relative'}>
      {canDisplayMediaControls && (
        <div
          className={
            'w-2/3 absolute left-1/2 right-1/2 -translate-x-1/2 bottom-10 h-16 z-[99999998]'
          }
        >
          {canDisplayMediaControls && (
            <MediaControls
              curTime={displayedTime}
              setCurTime={setCurTime}
              isPlaying={isPlaying}
              timeRange={timeRange}
              setIsPlaying={setIsPlaying}
            />
          )}
        </div>
      )}
      {canDisplayLegend && curLayer.layer === 1 && (
        <img
          className="w-56 h-10 flex flex-col text-center items-center justify-center p-2 z-[9999]
        absolute right-0 top-0"
          src={require('../../assets/my_radar_legend.png')}
          alt={'my radar legend'}
        />
      )}
      {canDisplayLegend && curLayer.layer !== 1 && (
        <Legend
          colorMap={layers[curLayer.layer].getColorMap()}
          units={layers[curLayer.layer].getUnits()}
        />
      )}
      {canDisplayCanvas && <canvas ref={canvasRef}></canvas>}
    </div>
  )
}

export default WetDogWeatherLayers
