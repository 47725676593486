export enum WetDogWeatherLayersEnum {
  MRMS = 0,
  RADAR = 1,
  CLOUDCEILING = 2,
  CLOUDCOVER = 3,
  TEMPERATURE = 4,
  WINDUV = 5,
  WIND80M = 6,
  WINDGUST = 7,
  CURRENT_CLOUDCEILING = 8,
  CURRENT_CLOUDCOVER = 9,
  CURRENT_TEMPERATURE = 10,
  CURRENT_WINDUV = 11,
  CURRENT_WIND80M = 12,
  CURRENT_WINDGUST = 13,
}
