import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const SessionRedirect = function () {
    let {id,token} = useParams()
    const navigate = useNavigate()
    useEffect(() => {
        token = token.replace(/\*/g, '.');
        // sessionStorage.setItem('token',token)
        localStorage.setItem('token',token)
        // sessionStorage.setItem('userId',id)
        localStorage.setItem('userId',id)
        navigate('/map')
    },[])
    
    return (<></>)
}
