const LOCAL_TWS_API_URL = 'http://localhost:1337'
const DEV_TWS_API_URL = 'https://test.truweathersolutions.com'
const JOINTDEV_API_URL = 'https://exp.truweathersolutions.com'
const STAGING_TWS_API_URL = 'https://staging.truflitev360.com'
const PROD_TWS_API_URL = 'https://api.truflitev360.com'

//https was not working on http://3.134.33.108 endpoint so using the prod endpoint
const LOCAL_WMSURL = 'https://wms.truflitev360.com'
const PROD_WMSURL = 'https://wms.truflitev360.com'

const LOCAL_TWS_V2_URL = 'http://localhost:8080'
//If using dev backend on local comment this
const DEV_TWS_V2_URL = 'https://dev-v2.truflitev360.com/'
//If using dev backend on local uncomment this
// const DEV_TWS_V15_URL = 'http://localhost:4200'
const STAGING_TWS_V15_URL = 'https://staging.truflitev360.com/'
const PROD_TWS_V2_URL = 'https://beta.truflitev360.com/'
const JOINTDEV_TWS_V2_URL ='https://experiment.truflitev360.com/'
const DEV_WDWSTACK = 'truwx-dev'
const PROD_WDWSTACK = 'truwx'
export const tempestClientID = '0248cda8-85fb-4065-8fb7-891a59e62e67'

export const TWS_API_URL =
  process.env.NODE_ENV === 'dev'
    ? DEV_TWS_API_URL
    : process.env.NODE_ENV === 'joint'
    ? JOINTDEV_API_URL
    : process.env.NODE_ENV === 'staging'
    ? STAGING_TWS_API_URL
    : process.env.NODE_ENV === 'production'
    ? PROD_TWS_API_URL
    : LOCAL_TWS_API_URL

export const WMSURL =
  process.env.NODE_ENV === 'dev'
    ? LOCAL_WMSURL
    : process.env.NODE_ENV === 'joint'
    ? PROD_WMSURL
    : process.env.NODE_ENV === 'staging'
    ? PROD_WMSURL
    : process.env.NODE_ENV === 'production'
    ? PROD_WMSURL
    : LOCAL_WMSURL

export const WDWSTACK =
  process.env.NODE_ENV === 'dev'
    ? DEV_WDWSTACK
    : process.env.NODE_ENV === 'joint'
    ? DEV_WDWSTACK
    : process.env.NODE_ENV === 'staging'
    ? PROD_WDWSTACK
    : process.env.NODE_ENV === 'production'
    ? PROD_WDWSTACK
    : DEV_WDWSTACK

  export const TWS_V15_URL =
    process.env.NODE_ENV === 'dev'
      ? DEV_TWS_V2_URL
      : process.env.NODE_ENV === 'staging'
      ? STAGING_TWS_V15_URL
      : process.env.NODE_ENV === 'joint'
      ? JOINTDEV_TWS_V2_URL
      : process.env.NODE_ENV === 'production'
      ? PROD_TWS_V2_URL
      : LOCAL_TWS_V2_URL