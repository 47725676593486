import './ApiLoader.css'

export const ApiLoader = () => {
    return (
        <div className="loading-indicator">
            <div className="loading-indicator-sq"></div>
            <div className="loading-indicator-sq"></div>
            <div className="loading-indicator-sq"></div>
            <div className="loading-indicator-sq"></div>
            <div className="loading-indicator-sq"></div>
            <div className="loading-indicator-sq"></div>
            <div className="loading-indicator-sq"></div>
            <div className="loading-indicator-sq"></div>
            <div className="loading-indicator-sq"></div>
        </div>
    )
}