import ReactDOM from 'react-dom'
import { App } from './App'
import { BrowserRouter } from 'react-router-dom'
import store from './store'
import { Provider } from 'react-redux'

// eslint-disable-next-line @typescript-eslint/no-empty-function
//if (process.env.NODE_ENV !== 'production') console.log = () => {}
// console.log("process.env.NODE_ENV:", process.env.NODE_ENV)
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)
