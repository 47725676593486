import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  setProperties,
  removeAlert,
  sideNavAlerts,
  showAlertNotification,
} from './AlertingSlice/AlertingSlice'

const removeAlertNotification: any[] = []
function useAlertSetting() {
  const [selectedProperties, setSelectedProperties] = useState(null)

  const dispatch = useDispatch()
  const openAlertSetting = (properties) => {
    setSelectedProperties(null)
    dispatch(setProperties(properties))
  }

  const closeAlertSetting = () => {
    setSelectedProperties(null)
  }

  const dismissAlert = (properties) => {
    dispatch(removeAlert(properties))
  }
  const addAlertSideNav = (properties) => {
    dispatch(sideNavAlerts(properties))
  }

  const addOnlyNotificationAlert = (properties) => {
    dispatch(showAlertNotification(properties))
  }

  const removingNotificationAlert = (properties) => {
    if (!removeAlertNotification?.includes(properties)) {
      removeAlertNotification.push(properties)
    }
  }

  return {
    selectedProperties,
    openAlertSetting,
    closeAlertSetting,
    dismissAlert,
    addAlertSideNav,
    addOnlyNotificationAlert,
    removingNotificationAlert,
    removeAlertNotification,
  }
}

export default useAlertSetting
