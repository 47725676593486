import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import VideoPlayer from "./VideoPlayer"


export default function IpCamera () {
    const streamUrl = 'https://camera.sensors.truweathersolutions.com/hls/stream.m3u8';
    const content = <VideoPlayer src={streamUrl} />
    useEffect(() => {
    }, [])
    return (
        <div className="z-[99999999] absolute inset-0 overflow-y-auto overflow-x-hidden fixed justify-center items-center">
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className="relative bg-none rounded-lg shadow dark:bg-gray-700">
                    <VideoPlayer src={streamUrl} />
                </div>
            </div>
        </div>

    )
}