import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchMETARData } from "./METARAPI";

import { MetarParsedData } from "../../interfaces/metar";

  const initialState = {
    data: [] as MetarParsedData[],
    status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
    error: null,
  };
  export const getMetarData = createAsyncThunk(
    'location/addNewLocation',
    async () => {
      const response = await fetchMETARData()
      return response
    }
  )
  
  const metarSlice = createSlice({
    name: 'metar',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getMetarData.pending, (state) => {
          state.status = 'loading';
          state.error = null;
        })
        .addCase(getMetarData.fulfilled, (state, action) => {
          state.status = 'succeeded';
        })
        .addCase(getMetarData.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });
    },
  });
  
  export default metarSlice.reducer;


