import { createContext, Dispatch, ReactElement, SetStateAction } from 'react'

interface TWSContextType {
  twsElements: ReactElement[]
  setTwsElements: Dispatch<SetStateAction<ReactElement[]>>
}

const TWSContext = createContext<TWSContextType | undefined>(undefined)

export default TWSContext
