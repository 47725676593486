import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import fetchMissionCASTData from './MissionCASTApiService'

export interface Payload {
  lat: number
  long: number
}

interface MissionCastState {
  showMissionCAST: boolean
}

const initialState = { showMissionCAST: false } as MissionCastState

export const fetchMissionCAST = createAsyncThunk(
  '/missionCAST',
  async (payload: Payload): Promise<string | any> => {
    let tempPayload=payload
    if(Number(tempPayload["lat"]) < -180){
      tempPayload["lat"] = Number(tempPayload["lat"])+360
    }  
    if(Number(tempPayload["long"]) < -180){
      tempPayload["long"] = Number(tempPayload["long"])+360
    }
    if(Number(tempPayload["lat"]) > 180) {
      tempPayload["lat"] = Number(tempPayload["lat"])-360
    }
    if(Number(tempPayload["long"]) > 180) {
      tempPayload["long"] = Number(tempPayload["long"])-360
    }
    tempPayload["lat"] = tempPayload["lat"].toString()
    tempPayload["long"] = tempPayload["long"].toString()
    const response = await fetchMissionCASTData(tempPayload)

    return response.data
  }
)

const MissionCastSlice = createSlice({
  name: 'missioncast',
  initialState,
  reducers: {
    toggleMissionCast: (state, action: PayloadAction<boolean>) => {
      state.showMissionCAST = action.payload
    },
  },
})

export const { toggleMissionCast } = MissionCastSlice.actions
export default MissionCastSlice.reducer
