/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
//npm module imports
import { Provider, useDispatch, useSelector } from 'react-redux'
import store, { RootState } from './store'
import { ReactElement, useState, useEffect, SyntheticEvent } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

//component imports
import 'normalize.css'
import './App.css'
import {
  selectedItemIds,
  selectedItems,
  prependMenuItem,
  removeMenuItem,
} from './menus/SideNavigation/SideNavigationSlice'
import SideNavigation from './menus/SideNavigation/SideNavigation'
import TopNavigation from './menus/TopNavigation/TopNavigation'
import LoginPage from './Components/Users/LoginPage/LoginPage'
import RegisterPage from './Components/Users/RegisterPage/RegisterPage'
import MapComponent from './map/MapComponent'
import ForgotPasswordPage from './Components/Users/ForgotPasswordPage/ForgotPasswordPage'
import UserProfile from './Components/Users/UserProfile/UserProfile'
import SlideOverDialog from './menus/SlideOverDialog/SlideOverDialog'
import TWSContext from './hooks/TWSMapContext'
import { SlideOverDialogProvider } from './menus/SlideOverDialog/SlideOverDialogContext'

import {
  setDefaultLocation,
  fetchUser,
  deleteUserLocation,
} from './Components/Users/UserSlice'
import MenuSelection from './Components/MenuSelection/MenuSelection'

import { Location } from './interfaces/Location'
import marker from './assets/marker.svg'
import SensorDetails from './Components/WeatherSensorDetails/SensorDetails'
import { IMenuItem } from './menus/SideNavigation/SideNavItems'
import icon from './assets/remove-menu-icon.svg'
import SlideOverDialogEvents from './menus/SlideOverDialog/SlideOverDialogEvents'

interface IDeleteLocationProps {
  id: number | string
  menuIdString: string
}
const DeleteLocation = (props: IDeleteLocationProps) => {
  const locations = useSelector((state: RootState) => state.user.locations)
  const dispatch = useDispatch()
  const defaultLocation = useSelector(
    (state: RootState) => state.user.defaultLocation
  )
  const clickHandler = async (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (locations?.length === 1) {
      alert('You need to have at least one location added to use the workbook.')
      return
    }
    if (confirm('Delete this location?')) {
      // sessionStorage.setItem('defaultLocation', locations[0].id)
      localStorage.setItem('defaultLocation', locations[0].id)
      const response = await dispatch(deleteUserLocation(props.id))
      if (response.payload !== null) {
        dispatch(removeMenuItem('locations-' + props.menuIdString))
        dispatch(selectedItems(defaultLocation.name))
        dispatch(setDefaultLocation(locations[0]))
      } else {
        alert('Delete Location Failed Please Try')
      }
    }
  }

  return (
    <div onClick={(e) => clickHandler(e)}>
      <img
        src={icon}
        alt="icon"
        className="h-5 mt-1 brightness-50 hover:brightness-100"
        title="Delete Location"
      />
    </div>
  )
}
import { CustomThresholds } from './Components/CustomThresholds/CustomThresholds'
import { SessionRedirect } from './Components/SessionRedirect/SessionRedirect'
import Notifier from './Components/Notifier/Notifier'

export const App = () => {
  const location = useLocation()
  const [sideNavExpanded, setSideNavExpanded] = useState(true)
  const [mapActive, setMapActive] = useState(false)
  const [selectedSideNav, setMessage] = useState('empty')
  const [numLocations, setNumLocations] = useState(0)
  const [twsElements, setTwsElements] = useState<ReactElement[]>([])
  const getDefaultLocation = useSelector(
    (state: RootState) => state.user.defaultLocation
  )
  const selectedItemsx = useSelector(
    (state: RootState) => state.sideNav.selectedItems
  )
  const sideNavCallback = (isExpanded: boolean) => {
    setSideNavExpanded(isExpanded)
  }

  // const userId = sessionStorage.getItem('userId')
  const userId = localStorage.getItem('userId')
  // const token = sessionStorage.getItem('token')
  const token = localStorage.getItem('token')

  const dispatch = useDispatch()

  const menuCallback = (extraData: any) => {
    return (menuItem: IMenuItem, event?: SyntheticEvent) => {
      dispatch(setDefaultLocation({ ...extraData.item }))
      // sessionStorage.setItem('defaultLocation', extraData.item.id)
      localStorage.setItem('defaultLocation', extraData.item.id)
    }
  }

  const defaultLocationHelper = (locations: Array<Location>) => {
    //get DefaultLocationId set in session storage
    // const defaultLocationId =
    //       parseInt(sessionStorage.getItem('defaultLocation') as string  )
    const defaultLocationId =
          parseInt(localStorage.getItem('defaultLocation') as string  )
    // check if the default location is saved
    if (defaultLocationId) {
      const defaultLocation = locations?.filter(
        (loc: Location) => loc.id == defaultLocationId
      )[0]

      if (defaultLocation) {
        return defaultLocation
      }
    }
    // sessionStorage.setItem('defaultLocation', locations[0].id as unknown as string)
    localStorage.setItem('defaultLocation', locations[0].id as unknown as string)
    return locations[0]
  }

  const callNumLocationAction = (locations: Array<Location>) => {
    if (
      numLocations === 0 ||
      locations.length != numLocations
    ) {
      setNumLocations(locations.length)
      dispatch(
        prependMenuItem({
          item: locations
            .slice(0, 3)
            .map((loc: Location, index) => ({
              id: 'locations-' + loc.name,
              label: loc.name,
              link: '',
              icon: marker,
              active: getDefaultLocation?.name === loc.name,
              specialComponent: (
                <DeleteLocation id={loc.id} menuIdString={loc.name} />
              ),
              callback: menuCallback({
                item: locations[index],
              }), // custom callback for menu item
              multiSelect: false,
            })),
          parentId: 'location-menu',
        })
      )
    }
  }
  //
  useEffect(() => {
    ;(async () => {
      if (userId) {
        const response = await dispatch(fetchUser())
        const locations: Array<Location> = response?.payload?.locations
        const defaultLocation: Location = defaultLocationHelper(locations);
      
        dispatch(selectedItems(defaultLocation.name))
        dispatch(selectedItemIds('locations-' + defaultLocation.name))

        dispatch(setDefaultLocation(defaultLocation))
        callNumLocationAction(locations)
      }
    })()
  }, [userId])

  useEffect(() => {
    const currentPath = location.pathname
    const isMapActive = currentPath === '/map' || currentPath === '/'
    setMapActive(isMapActive)
  }, [location.pathname])

  if (token === null || token === undefined || token === '') {
    /**
     * if token is null or undefined or token is empty, redirect to login page
     */
    return (
      <Routes>
        <Route
          path="/"
          element={
            <div className="flex flex-col bg-white w-full h-screen max-h-screen">
              <LoginPage />
            </div>
          }
        />
        <Route path="/session/:id/:token" element={<SessionRedirect />} />
        <Route
          path="/login"
          element={
            <div className="flex flex-col bg-white w-full h-screen max-h-screen">
              <LoginPage />
            </div>
          }
        />
        {/**
         * we also allow the user to register if they are not logged in
         */}
        <Route
          path="/signup"
          element={
            <div className="flex flex-col bg-white w-full h-screen max-h-screen">
              <RegisterPage />
            </div>
          }
        />
        {/**
         * and allow the user to recover their lost password if they are not logged in
         */}
        <Route
          path="/resetPassword"
          element={
            <div className="flex flex-col bg-white w-full h-screen max-h-screen">
              <ForgotPasswordPage />
            </div>
          }
        />
        {/**
         * But any other route will redirect to the login page
         */}
        <Route
          path="*"
          element={
            <div className="flex flex-col bg-white w-full h-screen max-h-screen ">
              <LoginPage />
            </div>
          }
        />
      </Routes>
    )
  } else {
    return (
      <Provider store={store}>
        <SlideOverDialogProvider>
          <TWSContext.Provider value={{ twsElements, setTwsElements }}>
            <div className="flex flex-col bg-white w-full h-screen max-h-screen overflow-y-clip">
              <TopNavigation burgerHandler={sideNavCallback}></TopNavigation>
              <Notifier position={'top-right'} />
              <div className="relative flex flex-row h-[calc(100vh-4rem)] basis-auto flex-grow shrink bg-white">
                <SideNavigation setMessage={setMessage}></SideNavigation>
                <MenuSelection />
                <div className="z-[99999998]">
                  {selectedItemsx.includes('Surface Observations') &&
                  mapActive ? (
                    <SlideOverDialog selectedSideNav={'Surface Observations'} />
                  ) : (
                    <div></div>
                  )}
                  {selectedItemsx.includes('Events List') && mapActive ? (
                    <SlideOverDialogEvents />
                  ) : (
                    <div></div>
                  )}
                </div>
                <div
                  className={`flex flex-col flex-grow z-1 ${
                    window.location.pathname === '/' ||
                    window.location.pathname === '/map'
                      ? ''
                      : 'hidden'
                  }`}
                >
                  <MapComponent selectedSideNav={selectedSideNav} />
                </div>
                <Routes>
                  {/*<Route*/}
                  {/*  path="/"*/}
                  {/*  element={*/}

                  {/*  }*/}
                  {/*/>*/}
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/signup" element={<RegisterPage />} />
                  <Route
                    path="/resetPassword"
                    element={<ForgotPasswordPage />}
                  />
                  {/* To put a component into the main section of the page, just wrap it in the MainContainer or make sure your
                  component fits into the layout.
                  **Use <Link to=...> for your internal links. */}
                  {/*<Route*/}
                  {/*  path="/map"*/}
                  {/*  element={*/}
                  {/*    <div className="flex flex-col flex-grow">*/}
                  {/*      <MapComponent selectedSideNav={selectedSideNav} />*/}
                  {/*    </div>*/}
                  {/*  }*/}
                  {/*/>*/}
                  <Route
                    path="/session/:id/:token"
                    element={<SessionRedirect />}
                  />
                  <Route path="/userprofile" element={<UserProfile />} />
                  <Route path="/sensordetails" element={<SensorDetails />} />
                  <Route
                    path="/customthresholds"
                    element={<CustomThresholds />}
                  />
                  <Route path="/sensordetails" element={<SensorDetails />} />
                  <Route
                    path="/customthresholds"
                    element={<CustomThresholds />}
                  />
                </Routes>
              </div>
            </div>
          </TWSContext.Provider>
        </SlideOverDialogProvider>
      </Provider>
    )
  }
}

export { DeleteLocation }
