const convertValuesToTruweatherUnits = function (value: number, units: string) {
  switch (units) {
    case 'F':
      value = (value - 273.15) * (9 / 5) + 32 // Kelvin to Farenheit formula
      break
    case 'C':
      value = value - 273.15 // Kelvin to Celsius formula
      break
    case 'sm':
      value = value / 1609.34
      break
    case 'ft':
      value = value * 3.28084
      break
    case 'kts':
      value = value * 1.94384
      break
    default:
      value = value
      break
  }

  return value
}

const formatValue = (value: number, layerName: string) => {
  switch (layerName) {
    case 'temperature':
      value = value.toFixed(1)
      break
    case 'windUV':
    case 'WindGust':
    case 'CloudCeiling':
      value = Math.round(value)
      break
    case 'Visibility':
      value = value.toFixed(2)
      break
    case 'radar':
      value = value.toFixed(1)
      break
    default:
      value = value
      break
  }

  return value
}

enum LayerToUnitsMapping {
  'temperature' = 'F',
  'windUV' = 'm/s',
  'Visibility' = 'sm',
  'CloudCeiling' = 'ft',
  'myradar' = 'dBz',
  'radar' = 'dBz',
  'WindGust' = 'm/s',
}

export { convertValuesToTruweatherUnits, LayerToUnitsMapping, formatValue }
