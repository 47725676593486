/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRef, useEffect, useState } from 'react'
import { WMSTileLayer } from 'react-leaflet'
import L, { Map as leafletMap, WMS } from 'leaflet'
import TWSOpacitySlider from '../api/OpacitySlider'

interface IBaseLayerProps {
  url: string
  // layers: string
  opacity: number
  attribution: string
  map?: leafletMap
}

// this doesn't work, but I'm not giving up hope yet
function addControlPlaceholders(map){
  const corners = map._controlCorners
  const l = 'leaflet-'
  const container = map._controlContainer

  function createCorner(vSide, hSide) {
    const className = l + vSide + ' ' + l + hSide

    corners[vSide + hSide] = L.DomUtil.create('div', className, container)
  }

  createCorner('verticalcenter', 'left')
  createCorner('verticalcenter', 'right')
  createCorner('horizontalcenter', 'top')
  createCorner('horizontalcenter', 'bottom')
}

const SectionalChartComponent = (props: IBaseLayerProps) => {
  const tileLayerRef = useRef<WMSTileLayer | null>(null)

  // TWSOpacitySlider is a vanillajs leaflet component
  const opacitySlider = new TWSOpacitySlider({
    title: 'Aeronautical Map Opacity',
    tileLayer: tileLayerRef.current,
    position: 'bottomright',
    opacity: props.opacity,
  })

  useEffect(() => {
    // the following addControlPlaceholders doesn't work, but I'm not giving up hope yet
    // addControlPlaceholders(tileLayerRef.current._map)
    // add the opacity slider to the tile layer
    opacitySlider.addTo(tileLayerRef.current._map)

    // inform the opacity slider of the tile layer
    opacitySlider.setTileLayer(tileLayerRef.current)
    return () => {
      // remove the opacity slider from the tile layer on clean up
      opacitySlider.remove()
    }
  }, [])

  return (
    <>
      <WMSTileLayer
        attribution={props.attribution}
        url={props.url}
        layers="sec"
        opacity={props.opacity}
        ref={(ref)=>{tileLayerRef.current = ref}}
      />
    </>
  )
}

const SatelliteComponent = (props: IBaseLayerProps) => {
  const tileLayerRef = useRef<WMSTileLayer | null>(null)
  const opacitySlider = new TWSOpacitySlider({title: 'Satellite Map Opacity', tileLayer: tileLayerRef.current, position: 'bottomright', opacity: props.opacity})

  useEffect(() => {
    opacitySlider.addTo(tileLayerRef.current._map)
    opacitySlider.setTileLayer(tileLayerRef.current)
    return () => {
      opacitySlider.remove()
    }
  }, [])

  return (
    <>
      <WMSTileLayer
        attribution={props.attribution}
        url={props.url}
        opacity={props.opacity}
        ref={(ref) => {
          tileLayerRef.current = ref
        }}
      ></WMSTileLayer>
    </>
  )
}

export { SectionalChartComponent, SatelliteComponent }
