/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SyntheticEvent } from 'react'
import { IMenuItem } from './SideNavItems'

export interface IMenuItemCallback {
  id: string
  callback: (menuItem: IMenuItem, Event?: SyntheticEvent) => void
}

export type MenuItemCallback = (
  menuItem: IMenuItem,
  Event?: SyntheticEvent,
  extraData?: any
) => void

export const menuCallbackRegistry: { [id: string]: MenuItemCallback } = {}

export function registerMenuItemCallback(
  id: string,
  callback: MenuItemCallback
): void {
  menuCallbackRegistry[id] = callback
}
