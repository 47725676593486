import PirepData from '../interfaces/pirep'
interface WeatherData {
  [key: string]: {
    value: any
    units?: string
  }
}

interface PIREPParsedData {
  latitude: number
  longitude: number
  properties?: { [key: string]: any }
}
export function createPopUpKeyValuePair(
  data: WeatherData
): { key: string; value: any }[] {
  return Object.entries(data).map(([key, value]) => ({
    key,
    value: value.value,
  }))
}

export function PIREPAPIParser(apiresponse: PirepData) {
  const parsedArray: PIREPParsedData[] = []
  if (apiresponse.features.message) {
    throw new Error(apiresponse.features.message)
  }
  apiresponse?.features?.forEach((feature) => {
    const newObject: PIREPParsedData = {
      latitude: feature.geometry.coordinates[1],
      longitude: feature.geometry.coordinates[0],
      properties: feature.properties,
    }
    parsedArray.push(newObject)
  })
  return parsedArray
}
