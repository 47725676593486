//eslint-disable-next-line @typescript-eslint/no-explicit-any
import { createSlice, PayloadAction,createAsyncThunk} from '@reduxjs/toolkit'
import { TWS_API_URL } from '../../../environment/apis.config'
import { timeouts } from '../../../services/RequestTimeouts'

interface WaypointWithAltitude {
  latDeg: number;
  lonDeg: number;
  altFtAGL: number;
}

export const parseWaypoints = (waypoints: string): WaypointWithAltitude[] => {
  const wsplit = waypoints.split(',');

  const waypointsWithAltitude: WaypointWithAltitude[] = [];
  for (let i = 0; i < wsplit.length; i += 3) {
    const latDeg = parseFloat(wsplit[i]);
    const lonDeg = parseFloat(wsplit[i + 1]);
    const altValue = parseFloat(wsplit[i + 2]);
    const altFtAGL = isNaN(altValue) ? 0 : altValue * 3.28;
    
    waypointsWithAltitude.push({
      latDeg,
      lonDeg,
      altFtAGL,
    });
  }

  return waypointsWithAltitude;
};

const timeoutSignal = (timeout: number) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  return { signal: controller.signal, clear: () => clearTimeout(id) };
};

export const fetchEval = createAsyncThunk(
  'routecast/fetchEval',
  async (requestBody: any, thunkAPI) => {
    const { signal, clear } = timeoutSignal(timeouts.routecast);
    try {
      const response = await fetch(
        TWS_API_URL+'/routecast/evaluate-json',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(requestBody),
          signal, // aborts the request if timeout is reached
        }
      );

      clear(); // Clear the timeout if the request was successful

      if (!response.ok) {
        const errorData = await response.json();
        return thunkAPI.rejectWithValue(errorData);
      }

      const responseData = await response.json();
      return responseData;
    } catch (error) {
      clear() // Clear the timeout if the request was unsuccessful too.
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchOpt = createAsyncThunk(
  'routecast/fetchOpt',
  async (requestBody: any, thunkAPI) => {
    const { signal, clear } = timeoutSignal(timeouts.routecast);
    try {
      const optResp = await fetch(
        TWS_API_URL+'/routecast/optimize-json',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(requestBody),
          signal, // aborts the request if timeout is reached
        }
      );
      clear(); // Clear the timeout if the request was successful

      if (!optResp.ok) {
        const errorData = await optResp.json();
        return thunkAPI.rejectWithValue(errorData);
      }

      const optRespData = await optResp.json();
      return optRespData;
    } catch (error) {
      clear() // Clear the timeout if the request was unsuccessful too.
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const routecastSlice = createSlice({
  name: 'routecast',
  initialState: {
    waypoints: '',
    evalApiResponse: null,
    optApiResponse: null,
    evalApiError: null,
    optApiError: null,
    dropDown: false,
    startDate: null,
    drawnPath: null,
    showRouteCAST: false,
    editRoutecast: true
  } ,
  reducers: {
    setWaypoints: (state, action: PayloadAction<string>) => {
      state.waypoints = action.payload
    },
    setEvalApiResponse: ( state, action: PayloadAction<any>) =>{
      state.evalApiResponse = action.payload;
    },
    setOptApiResponse: ( state, action: PayloadAction<any>) =>{
      state.optApiResponse = action.payload;
    },
    setDropDown: (state,action) => {
      state.dropDown = action.payload
    },
    clearEvalApiResponse: (state) => {
      state.evalApiResponse = null;
    },
    setStartDate: (state,action) => {
      state.startDate = action.payload;
    }, 
    clearStartDate: (state) => {
      state.evalApiResponse = null;
    },
    setDrawnPolyline: (state, action: PayloadAction<any>) => {
      state.drawnPath = action.payload;
    },
    toggleRouteCast: (state, action: PayloadAction<boolean>) => {
      state.showRouteCAST = action.payload
    },
    routeCastEdit: (state, action: PayloadAction<boolean>) => {
      state.editRoutecast = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEval.fulfilled, (state, action) => {
        state.evalApiResponse = action.payload;
        state.evalApiError = null; // Clear error on successful response
      })
      .addCase(fetchEval.rejected, (state, action) => {
        state.evalApiError = action.payload || 'Unknown error during evaluation';
      })
      .addCase(fetchOpt.fulfilled, (state, action) => {
        state.optApiResponse = action.payload;
        state.optApiError = null; // Clear error on successful response
      })
      .addCase(fetchOpt.rejected, (state, action) => {
        state.optApiError = action.payload || 'Unknown error during optimization';
    });
  },
})

export const { setWaypoints,setEvalApiResponse, setOptApiResponse,setDropDown,clearEvalApiResponse, setStartDate,clearStartDate,setDrawnPolyline, toggleRouteCast, routeCastEdit } = routecastSlice.actions
export default routecastSlice.reducer
